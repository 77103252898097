
import * as devKeys from "./dev";
import * as prodKeys from "./dev";

export const keys = () => {

    if (process.env.NODE_ENV === "development") {
        return { ...devKeys.keys }
    } else {
        return { ...prodKeys.keys }
    }
}