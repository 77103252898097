import { keys } from "../../config/keys";

export type GridActionProps = {
  onEditClick?: (val: any) => void;
  onDeleteClick?: (val: any) => void;
  onViewClick?: (val: any) => void;
  showEditButton?: Boolean;
  showDeleteButton?: Boolean;
  editLabel?: string;
  deleteLabel?: string;
  showEditLabel?: boolean;
  showDeleteLabel?: boolean;
  viewLabel?: string;
  showViewLabel?: boolean;
  showViewButton?: Boolean;
  viewButton?: Boolean;
  data: any;
  cell?: any;
  isAggrid?: boolean;
};

const GridActions = (props: GridActionProps) => {
  const {
    data,
    isAggrid = true,
    onEditClick = () => {},
    onDeleteClick = () => {},
    onViewClick = () => {},
    showEditButton = true,
    showDeleteButton = true,
    editLabel = " Edit",
    deleteLabel = " Delete",
    showEditLabel = false,
    showDeleteLabel = false,
    showViewLabel = false,
    showViewButton = false,
    viewLabel = "View",
    viewButton = false,
    cell
  } = props;

  const actionData = isAggrid ? data : cell._cell.row.data;
  return (
    // <span className="agGrid-button-container gt-2">
    <div className="row d-flex d-flex-row g-1">
      <div className="col">
        {showEditButton && (
          <button
            className="btn btn-success btn-sm agGrid-button"
            type="button"
            onClick={() => onEditClick(actionData)}
          >
            <i className="fa-solid fa-pencil"></i>
            {showEditLabel && editLabel}
          </button>
        )}
      </div>

      <div className="col">
        {viewButton && (
          <button
            className="btn bg-info text-white btn-sm agGrid-button"
            type="button"
            onClick={() => onViewClick(actionData)}
          >
            <i className="fa-solid fa-eye "></i>
          </button>
        )}
      </div>
      <div className="col">
        {showDeleteButton && (
          <button
            className="btn btn-danger btn-sm agGrid-button"
            type="button"
            onClick={() => onDeleteClick(actionData)}
          >
            <i className="fa-solid fa-trash"></i>
            {showDeleteLabel && deleteLabel}
          </button>
        )}
      </div>
      {showViewButton && (
        <div className="col">
          <a
            href={`${keys().ServicePath}/${actionData.data.filePath}`}
            target="_blank"
            rel="noopener noreferrer"
            className="btn btn-info btn-sm agGrid-button"
          >
            <i className="fa-solid fa-eye"></i>
            {showViewLabel && viewLabel}
          </a>
        </div>
      )}
    </div>
    // </span>
  );
};

export default GridActions;
