import * as yup from "yup";

export const transactionSchema = yup.object({
    chequeNumber: yup.string().required("Cheque number is required."),
    chequeAmount: yup.number().required("Cheque amount is required.").min(1, "Amount should be greater than 0"),
    isReceivable: yup.bool(),
    bankName: yup.string().when("isReceivable", { is: (isReceivable: boolean) => isReceivable === true, then: yup.string().ensure().required("Bank is required.") }),
    vendorName: yup.string().ensure().required("Vendor/Customer is required."),
    // lastName: yup.string().required("Last Name is required."),
    // PAN: yup.string().required("PAN is required."),
    // aadhar: yup.string().required("Aadhar is required."),
    // email: yup.string().required("Email is required."),
    // mobile: yup.string().required("Aadhar is required.").min(10, "Phone number must be atleast 1o digits"),
})

export const transactionUpdateSchema = yup.object({
    chequeMasterId: yup.string().required("Please select cheque number"),
    chequeStatusId: yup.string().required("Please select cheque status")
})