/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  MutableRefObject
} from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import { chequeActions } from "../../redux/actions";
import { useAppSelector } from "../../redux/store/hooks";
import GridActions from "../common/GridActions";
import { toast } from "react-toastify";
import { defaultColDefParams } from "../common/agGridCommon";
import { confirmAlert } from "react-confirm-alert";

const ChequeEntries = () => {
  const gridRef = useRef() as MutableRefObject<AgGridReact> | null;
  const dispatch = useDispatch<any>();
  const {
    cheque: { cheque_entries_list }
  } = useAppSelector((obj) => obj);
  const [rowData, setRowData] = useState([]);
  const navigate = useNavigate();

  const onGridEdit = (val: any) => {
    if (val.data.isReceivable === 1) {
      navigate(`/app/cheque/receivables/${val.data.id}`);
    } else {
      navigate(`/app/cheque/payables/${val.data.id}`);
    }
  };

  const onDeleteClick = (val: any) => {
    confirmAlert({
      title: "Delete Confirmation",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          //@ts-ignore
          onClick: () => {
            dispatch(
              chequeActions.deleteCheque(
                val.data.id,
                () => {
                  dispatch(
                    chequeActions.get_cheque_enties_list({
                      onError: (err) => {
                        toast.error(err.toString());
                      }
                    })
                  );
                  toast.success("Cheque entries deleted successfully");
                },
                (err: any) => {
                  toast.error(err.toString());
                }
              )
            );
          }
        },
        {
          label: "No",
          //@ts-ignore
          onClick: () => {}
        }
      ]
    });
  };

  const [columnDefs] = useState([
    { field: "name", headerName: "Name", width: 150 },
    { field: "bankName", headerName: "Bank" },
    {
      field: "chequeDate",
      headerName: "Cheque Date",
      filter: "agDateColumnFilter"
    },
    { field: "chequeNumber", headerName: "Cheque #", width: 120 },
    { field: "chequeAmount", headerName: "Amount" },
    { field: "status", headerName: "Status", filter: "agSetColumnFilter" },
    {
      field: "isReceivableName",
      headerName: "Receivable/Payable",
      filter: "agSetColumnFilter"
    },
    {
      headerName: "Actions",
      pinned: "right",
      cellRendererFramework: (val: any) => (
        <GridActions
          data={val}
          onEditClick={onGridEdit}
          onDeleteClick={onDeleteClick}
        />
      ),
      field: "id",
      colId: "params",
      suppressFilter: true,
      width: 80,
      enableSorting: false
    }
  ]);

  const defaultColDef = useMemo(() => {
    return defaultColDefParams;
  }, []);

  useEffect(() => {
    dispatch(
      chequeActions.get_cheque_enties_list({
        onError: (err) => {
          toast.error(err.toString());
        }
      })
    );
  }, []);

  useEffect(() => {
    setRowData(cheque_entries_list);
  }, [cheque_entries_list]);

  const rowClassRules = useMemo(() => {
    return {
      overdue: 'data.status==="Overdue"',
      cleared: 'data.status==="Cleared"'
    };
  }, []);

  const [open, setOpen] = useState(false);
  return (
    <div className="container-fluid">
      <h1 className="header-title">{`Cheque Entries`}</h1>

      <div className="card">
        <div className="card-header">
          <h5 className="card-title">Cheque Entries</h5>
          <h6 className="card-subtitle text-muted">
            {`List of all the cheque entries with status`}
          </h6>
        </div>
        <div className="card-body">
          <div className="ag-theme-balham agGrid-height-width">
            <AgGridReact
              // ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              // onFirstDataRendered={onFirstDataRendered}
              rowClassRules={rowClassRules}
            ></AgGridReact>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChequeEntries;
