/* eslint-disable @typescript-eslint/no-unused-vars */
import axios from "../../components/common/axios";
import { AxiosResponse } from "axios";
import { ChequeTransactionType } from "../../components/Transaction/ChequeTransaction";
import { toast } from "react-toastify";
import { keys } from "../../config/keys";
import { CHEQUE_ENTRY_LIST, CHEQUE_STATUS_MASTER } from "../constTypes/cheque";
import { ChequeStatusTransactionType } from "../../components/Transaction/UpdateChequeStatus";

export type Callbacks = {
  onSuccess?: (val: any) => void;
  onError?: (val: any) => void;
};

export const save =
  (data: ChequeTransactionType, onSuccess?: any, onError?: any) =>
    async (dispatch: any) => {
      const form = new FormData();

      Object.entries(data).forEach(([key, value]) => {
        form.append(key, value);
      });

      await axios({
        method: "post",
        url: `${keys().ServicePath}/api/cheque`,
        data: form,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          // toast.success("Added successfully!!!")
          onSuccess(res);
        })
        .catch((e) => {
          // toast.error(e.toString())
          onError(e);
        });
    };

export const MultipleSave =
  (data: any, onSuccess?: any, onError?: any) => async (dispatch: any) => {

    console.log(data)
    await axios({
      method: "post",
      url: `${keys().ServicePath}/api/cheque/multiCheque`,
      data: data,
      headers: { "Content-Type": "application/json" },
    })
      .then((res) => {
        // toast.success("Added successfully!!!");
        onSuccess(res);
      })
      .catch((e) => {
        // toast.error(e.toString())
        onError(e);
      });
  };

export const update =
  (data: ChequeTransactionType, onSuccess?: any, onError?: any) =>
    async (dispatch: any) => {
      const form = new FormData();
      Object.entries(data).forEach(([key, value]) => {
        form.append(key, value);
      });
      await axios({
        method: "patch",
        url: `/api/cheque`,
        data: form,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then((res) => {
          // toast.success("Added successfully!!!")
          if (onSuccess) onSuccess(res);
        })
        .catch((e) => {
          //  toast.error(e.toString())
          if (onError) onError(e);
        });
    };

export const deleteCheque = (id: number, onSuccess?: any, onError?: any) =>
  async (dispatch: any) => {
    await axios({
      method: "post",
      url: `/api/cheque/delete_cheque`,
      data: { id: id },
    })
      .then((res) => {
        // toast.success("Added successfully!!!")
        if (onSuccess) onSuccess(res);
      })
      .catch((e) => {
        //  toast.error(e.toString())
        if (onError) onError(e);
      });
  };

const _chequeList = (data: any) => {
  return {
    type: CHEQUE_ENTRY_LIST,
    payload: data,
  };
};

export const get_cheque_enties_list =
  ({ onSuccess, onError }: Callbacks) =>
    async (dispatch: any) => {
      await axios
        .get("/api/cheque")
        .then((res: AxiosResponse) => {
          // toast.success("Added successfully!!!")
          dispatch(_chequeList(res.data.rows));
          if (onSuccess) onSuccess(res);
        })
        .catch((e) => {
          // toast.error(e.toString())
          if (onError) onError(e);
        });
    };

const _cheque_status_master = (data: any) => {
  return {
    type: CHEQUE_STATUS_MASTER,
    payload: data,
  };
};

export const get_cheque_details_by_id =
  (id: number, { onSuccess, onError }: Callbacks) =>
    async (dispatch: any) => {
      await axios
        .get(`/api/cheque/${id}`)
        .then((res: AxiosResponse) => {
          // toast.success("Added successfully!!!")
          // dispatch(_cheque_details_by_id(res.data.rows));
          if (onSuccess) onSuccess(res);
        })
        .catch((e) => {
          // toast.error(e.toString())
          if (onError) onError(e);
        });
    };

export const get_cheque_status_master =
  ({ onSuccess, onError }: Callbacks) =>
    async (dispatch: any) => {
      await axios
        .get(`/api/cheque/cheque_status_master`)
        .then((res: AxiosResponse) => {
          // toast.success("Added successfully!!!")
          dispatch(_cheque_status_master(res.data.rows));
          if (onSuccess) onSuccess(res);
        })
        .catch((e) => {
          // toast.error(e.toString())
          if (onError) onError(e);
        });
    };

export const get_cheque_transaction_history =
  (id: number, { onSuccess, onError }: Callbacks) =>
    async (dispatch: any) => {
      await axios
        .get(`/api/cheque/cheque_status_transaction_history/${id}`)
        .then((res: AxiosResponse) => {
          if (onSuccess) onSuccess(res);
        })
        .catch((e) => {
          if (onError) onError(e);
        });
    };

export const update_cheque_staus =
  (data: ChequeStatusTransactionType, callback?: Callbacks) =>
    async (dispatch: any) => {
      await axios({
        method: "post",
        url: `/api/cheque/update_cheque_status`,
        data,
      })
        .then((res) => {
          // toast.success("Added successfully!!!")
          if (callback?.onSuccess) callback.onSuccess(res);
        })
        .catch((e) => {
          //  toast.error(e.toString())
          if (callback?.onError) callback.onError(e);
        });
    };
