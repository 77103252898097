/* eslint-disable @typescript-eslint/no-unused-vars */
import { yupResolver } from "@hookform/resolvers/yup";
import {
  useEffect,
  useRef,
  useState,
} from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import CurrencyInput from "react-currency-input-field";
import { format } from "date-fns";
// import DatePicker, { DayValue } from "react-modern-calendar-datepicker";
// import "react-modern-calendar-datepicker/lib/DatePicker.css";
import Select from "react-select";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import {
  chequeActions,
  commonActions,
  vendorActions,
} from "../../redux/actions";
import { useAppSelector } from "../../redux/store/hooks";
import { SelectedOption } from "../Vendor/AddressDetails";
import { VendorDropDown } from "../Vendor/PersonalDetails";
import { transactionSchema } from "./validationSchema";
import {
  ChequeTransactionProps,
  ChequeTransactionType,
} from "./ChequeTransaction";
import { toast } from "react-toastify";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
//import { Link } from "react-router-dom";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

let rowImmutableStore: any[];

const ChequeTransactionPayable = (props: ChequeTransactionProps) => {
  const { isReceivable = 1 } = props;
  const dispatch = useDispatch<any>();
  const { vendor_dropdown, banks } = useAppSelector((obj) => obj);
  const gridRef = useRef<any | null>(null);

  //   const [selectedDay, setSelectedDay] = useState<DayValue>(null);

  //   const formatInputValue = () => {
  //     if (!selectedDay) return "";
  //     return `${selectedDay?.day}-${selectedDay?.month}-${selectedDay?.year}`;
  //   };

  const initialData: ChequeTransactionType = {
    chequeDate: new Date(),
    vendorId: 0,
    vendorName: "",
    bankId: 0,
    bankName: "",
    chequeNumber: "",
    chequeAmount: 0,
    isReceivable: isReceivable,
    photoPath: "",
    remarks: "",
  };

  const [formData, setFormData] = useState<ChequeTransactionType>(initialData);
  const [selectedVendor, setSelectedVendor] = useState<VendorDropDown | null>(
    null
  );
  const [selectedBank, setSelectedBank] = useState<SelectedOption | null>(null);
  const [files, setFiles] = useState<any>([]);
  const [NoOfCheques, setNoOfCheques] = useState<any>([]);
  const [rowData, setRowData] = useState<ChequeTransactionType | null | any>(
    []
  );
  const [IsDateValid, setIsDateValid] = useState<any>([true]);
  var Ids: number;

  const [columnDefs] = useState([
    {
      cellRenderer: function (params: any) {
        return params.value;
      },
      headerName: "Sr No",
      valueGetter: "parseInt(node.id)+1",
    },
    { field: "vendorName", headerName: "Vendor Name" },
    //  { field: "bankName", headerName: "Bank Name" ,},
    {
      field: "chequeDate",
      headerName: "Cheque Date",
      editable: (e: any) => {
        const dateFormat = /^\d{4}\\-\d{2}\\-\d{2}$/;
        var dateValid = dateFormat.test(e.data.chequeDate);

        if (Ids !== e.node.id) {
          var a = window.confirm("Are you sure you want to change this date");
          if (a === true) {
            Ids = e.node.id;
            return true;
          } else {
            Ids = e.node.id;

            return false;
          }
        }
        return dateValid === false ? true : false;
      },
      singleClickEdit: true,
      onCellValueChanged: (e: any) => {
        const dateFormat = /^\d{4}\\-\d{2}\\-\d{2}$/;
        var DateInfo = [
          dateFormat.test(e.newValue),
          `Enter proper date at row number ${+e.node.id + 1}`,
        ];
        setIsDateValid(DateInfo);
      },
    },
    { field: "chequeNumber", headerName: "Cheque Number" },
    { field: "chequeAmount", headerName: "Cheque Amount" },

    // {
    //   headerName: "Actions",
    //   pinned: "right",
    //   cellRendererFramework: (val: any) => (
    //     <GridActions
    //       data={val}
    //       // onEditClick={onGridEdit}
    //       // onDeleteClick={onGridDelete}
    //     />
    //   ),
    //   field: "id",
    //   colId: "params",
    //   suppressFilter: true,
    //   enableSorting: false,
    // },
  ]);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(transactionSchema),
  });

  useEffect(() => {
    dispatch(vendorActions.getVendorDropDownList());
    dispatch(commonActions.get_bank_list());
    setValue("isReceivable", isReceivable);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const calculations = (e: any) => {
    e.preventDefault();
    var i: number;
    i = 0;
    var d: number = 0;
    var newData = []
    var newDate: any;

    for (NoOfCheques; i < NoOfCheques; i++) {
      newDate =
        i > 0 ? moment(newDate).add(30, "day") : moment(formData.chequeDate);

      var IsSunday =
        moment(newDate).day("Sunday").format("DD/MM/YYYY") ===
        moment(newDate).format("DD/MM/YYYY");

      if (IsSunday) {
        newDate = moment(newDate).add(1, "day");
      }

      var newChequeNumber: any = formData.chequeNumber;
      newChequeNumber = parseInt(newChequeNumber) + d;

      d++;

      newData.push({
        bankId: formData.bankId,
        bankName: formData.bankName,
        chequeAmount: formData.chequeAmount,
        chequeDate: moment(newDate).format("YYYY-MM-DD"),

        chequeNumber: newChequeNumber,
        isReceivable: formData.isReceivable,
        photoPath: formData.photoPath,
        remarks: formData.remarks,
        vendorId: formData.vendorId,
        vendorName: formData.vendorName,
      });
    }

    setRowData(newData);
  };

  const clear = () => {
    setRowData("");
    setSelectedBank(null);
    setNoOfCheques("");
    setSelectedVendor(null);
    formData.chequeNumber = "";
    formData.chequeAmount = 0;
  };

  const onSubmit = (data: any, e: any) => {
    if (IsDateValid[0] === false) {
      alert(IsDateValid[1]);
    } else {
      e.preventDefault();
      dispatch(
        chequeActions.MultipleSave(
          {
            rowData,
            chequeDate: format(formData.chequeDate as Date, "yyyy-MM-dd"),
          },
          (res: any) => {
            setFormData(initialData);
            setSelectedVendor(null);
            setSelectedBank(null);
            setValue("vendorName", "");
            setValue("bankName", "");
            reset(initialData);
            toast.success("Cheque Entry Added");
            clear();
          },
          (err: any) => {
            toast.error(err.toString());
          }
        )
      );
    }
  };

  return (
    <div className="container-fluid">
      <h1 className="header-title">Cheque Entries</h1>
      <div className="card">
        <div className="card-header">
          <h5 className="card-title">Cheque Entries</h5>
          <h6 className="card-subtitle text-muted">
            {`Transaction entries for ${
              isReceivable ? `receivables` : `payables`
            }.`}
          </h6>
        </div>

        <div className="card-body">
          <form noValidate>
            <div className="row g-2 mb-2">
              <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12 ">
                <label className="form-label">
                  {`${
                    isReceivable ? `Received by` : `Payable to`
                  } Customer/Vendor`}
                </label>

                <Controller
                  name={"vendorName"}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...register("vendorName")}
                      {...field}
                      className={`react-select ${
                        errors?.vendorName ? `error` : ``
                      }`}
                      value={selectedVendor}
                      options={vendor_dropdown}
                      onChange={(option: any) => {
                        setSelectedVendor(option as VendorDropDown);
                        setValue("vendorName", option.label);
                        setFormData((prev) => ({
                          ...prev,
                          vendorId: option.value as number,
                          vendorName: option.label,
                        }));
                      }}
                    />
                  )}
                />
                {errors?.vendorName && (
                  <div className="invalid-feedback">
                    {errors?.vendorName?.message}
                  </div>
                )}
              </div>
              <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12 ">
                <label className="form-label">No of cheques</label>
                <input
                  {...register("NoOfCheques")}
                  className={`form-control ${
                    errors?.NoOfCheques ? `error` : ``
                  } `}
                  name={"NoOfCheques"}
                  placeholder="No Of Cheques"
                  onChange={(e) => {
                    setNoOfCheques(e.target.value);
                  }}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  value={NoOfCheques}
                />
                {errors?.NoOfCheques && (
                  <div className="invalid-feedback">
                    {errors?.NoOfCheques?.message}
                  </div>
                )}
              </div>
            </div>

            <div className="row g-2 mb-2">
              <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12">
                <label className="form-label">Cheque Date</label>
                {/* <DatePicker
                value={selectedDay}
                onChange={setSelectedDay}
                inputPlaceholder="Select a date" // placeholder
                // formatInputText={formatInputValue} // format value
                inputClassName={`form-control`} // custom class
                shouldHighlightWeekends
              /> */}
                <DatePicker
                  {...register("chequeDate")}
                  selected={formData?.chequeDate as Date}
                  onChange={(date: Date) =>
                    setFormData((prev: ChequeTransactionType) => ({
                      ...prev,
                      chequeDate: date,
                    }))
                  }
                  showYearDropdown
                  dateFormat={"dd/MM/yyyy"}
                  className={`form-control ${
                    errors?.chequeDate ? `error` : ``
                  } `}
                />
              </div>
              {isReceivable === 1 && (
                <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12 ">
                  <label className="form-label">Bank</label>

                  <Controller
                    name={"bankName"}
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...register("bankName")}
                        {...field}
                        className={`react-select ${
                          errors?.bankName ? `error` : ``
                        }`}
                        value={selectedBank}
                        options={banks}
                        onChange={(option: any) => {
                          setSelectedBank(option as SelectedOption);
                          setValue("bankName", option.label);
                          setFormData((prev) => ({
                            ...prev,
                            bankName: option.label,
                            bankId: option.value,
                          }));
                        }}
                      />
                    )}
                  />
                  {errors?.bankName && (
                    <div className="invalid-feedback">
                      {errors?.bankName?.message}
                    </div>
                  )}
                </div>
              )}
              <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12 ">
                <label className="form-label">Cheque Number</label>
                <input
                  {...register("chequeNumber")}
                  className={`form-control ${
                    errors?.chequeNumber ? `error` : ``
                  } `}
                  name={"chequeNumber"}
                  placeholder="Cheque Number"
                  onChange={handleChange}
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  value={formData.chequeNumber}
                />
                {errors?.chequeNumber && (
                  <div className="invalid-feedback">
                    {errors?.chequeNumber?.message}
                  </div>
                )}
              </div>
              <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12 ">
                <label className="form-label">Cheque Amount</label>
                <CurrencyInput
                  {...register("chequeAmount")}
                  id="chequeAmount"
                  name="chequeAmount"
                  className={`form-control ${
                    errors?.chequeAmount ? `error` : ``
                  } `}
                  placeholder="Please enter amount"
                  value={formData.chequeAmount}
                  onValueChange={(value, name) => {
                    setValue("chequeAmount", value);
                    setFormData((prev: ChequeTransactionType) => ({
                      ...prev,
                      chequeAmount: parseFloat(value!) || 0,
                    }));
                  }}
                  prefix="Rs. "
                  disableAbbreviations={false}
                />

                {/* ;
                <input
                  {...register("chequeAmount")}
                  className={`form-control ${
                    errors?.chequeAmount ? `error` : ``
                  } `}
                  name={"chequeAmount"}
                  placeholder="Cheque Amount"
                  onChange={handleChange}
                  value={formData.chequeAmount}
                /> */}
                {errors?.chequeAmount && (
                  <div className="invalid-feedback">
                    {errors?.chequeAmount?.message}
                  </div>
                )}
              </div>
              <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                <label className="form-label">Remarks</label>
                <textarea
                  className={`form-control`}
                  name={"remarks"}
                  placeholder="Remarks"
                  onChange={handleChange}
                  value={formData.remarks}
                  rows={3}
                />
              </div>
              <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                <button
                  className="col-2 btn   btn-success  btn-sm m-5"
                  onClick={calculations}
                  disabled={
                    formData.vendorId === 0 ||
                    formData.chequeNumber === "" ||
                    formData.chequeAmount === 0
                      ? true
                      : false
                  }
                >
                  <i className="fa-solid fa-plus"></i> {" Add"}
                </button>
              </div>
              <div
                className="ag-theme-balham agGrid-height-width"
                hidden={rowData.length > 0 ? false : true}
              >
                <AgGridReact
                  rowData={rowData}
                  columnDefs={columnDefs}
                  animateRows={true}
                  pagination={true}
                  paginationPageSize={15}

                  //                   onCellClicked={(e) => {
                  // if(e.colDef.field === "chequeDate"){
                  //   window.confirm("Are sure you want to change this date")
                  // }

                  //                   }

                  //                   }
                ></AgGridReact>
              </div>
              <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 ">
                <label className="form-label">Image (Cheque)</label>

                <FilePond
                  files={files}
                  onupdatefiles={(file) => {
                    setFiles(file);
                    setFormData((prev: ChequeTransactionType) => ({
                      ...prev,
                      photo: file && file.length > 0 ? file[0].file : null,
                    }));
                  }}
                  allowMultiple={false}
                  // server="/api"
                  name="files"
                  labelIdle='Drag & Drop your Cheque image or <span class="filepond--label-action">Browse</span>'
                />
              </div>
            </div>
            <div className="row g-2">
              <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12">
                <button
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                  className="btn btn-success w-100 btn-sm"
                  disabled={
                    formData.vendorId === 0 ||
                    formData.chequeNumber === "" ||
                    formData.chequeAmount === 0
                      ? true
                      : false
                  }
                  // style={{ marginRight: 2 }}
                >
                  <i className="fa-solid fa-floppy-disk"></i> {" Save"}
                </button>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12">
                <button
                  type="button"
                  className="btn btn-danger w-100 btn-sm"
                  onClick={() => {
                    reset();
                    clear();
                  }}
                  // style={{ marginRight: 2 }}
                >
                  <i className="fa-solid fa-xmark"></i> {" Cancel"}
                </button>

                {/* <Link
                  to={"/app/vendor"}
                  className="btn btn-danger w-100 btn-sm"
                >
                  <i className="fa-solid fa-xmark"></i>
                  {" Cancel"}
                </Link> */}
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ChequeTransactionPayable;
