const Settings = () => {
  return (
    <div className="container-fluid">
      <h1 className="header-title">{`Settings`}</h1>

      <div className="card">
        <div className="card-header">
          <h5 className="card-title">Settings</h5>
          <h6 className="card-subtitle text-muted">
            {`Email/SMS/Other types of settings`}
          </h6>
        </div>
        <div className="card-body"></div>
      </div>
    </div>
  );
};

export default Settings;
