const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR"
});

export const keys = {


    //QA
    ServicePath: "https://controlcapi.imageio.in",
    ServiceIP: "https://controlcapi.imageio.in",
    authorizedURL: "http://controlc.imageio.in",

    // LOCAL
    // ServicePath: "http://localhost:5003",
    // ServiceIP: "http://localhost:5003",
    // authorizedURL: "http://localhost:3000",
    formatter

};
