
export const defaultColDefParams = {
    floatingFilter: true,
    filter: "agTextColumnFilter",
    resizable: true,
    width: 150,
    sortable: true,
    wrapText: true,
    autoHeight: true
}

