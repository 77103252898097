

import { combineReducers } from "redux";
import * as vendorReducer from "./vendor";
import * as commonReducer from "./common";
import * as chequeReducer from "./cheque";
import * as dashboardReducer from "./dashboard";

const rootReducer = combineReducers({
    ...vendorReducer,
    ...commonReducer,
    ...chequeReducer,
    ...dashboardReducer
});

export default rootReducer;


export type Actions = {
    type: string;
    payload: any;
}
