import axios from "./components/common/axios";
import { AxiosResponse } from "axios";
import { LoginData } from "./components/Login";

const authProvider = {
  isAuthenticated: false,

  async signin(user: LoginData, onSuccess: any, onError: any) {

    await axios.post(`/api/auth/login`, { ...user }).then((res: AxiosResponse) => {
  
      if (res.data.rows.length > 0) {
        onSuccess({ user: res.data.rows[0], menu: res.data.menu });
      }
      else{
        onError("Invalid Login Credentials")
      }
      // callback(res.data.rows)    


    }).catch((e) => {
      onError(e)
    
    })


  },
  signout(callback: VoidFunction) {
    authProvider.isAuthenticated = false;
    setTimeout(callback, 100);
  },
};

export { authProvider };

