import { Link, Route, Routes } from "react-router-dom";
import avtar from "../Assets/images/avatar4.png";
import AppSideMenu from "./AppSideMenu";

type AppSideBarProps = {
  isToggled?: boolean;
};
const AppSideBar = (props: AppSideBarProps) => {
  const { isToggled = false } = props;
  return (
    <nav className={isToggled ? "themesidebar toggled" : "themesidebar"}>
      <div className="themesidebar-content">
        <Link to="/app/" className="themesidebar-brand">
          {/* <img src={logo} alt="logo" /> */}
          {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M512 64C547.3 64 576 92.65 576 128V384C576 419.3 547.3 448 512 448H64C28.65 448 0 419.3 0 384V128C0 92.65 28.65 64 64 64H512zM112 224C103.2 224 96 231.2 96 240C96 248.8 103.2 256 112 256H272C280.8 256 288 248.8 288 240C288 231.2 280.8 224 272 224H112zM112 352H464C472.8 352 480 344.8 480 336C480 327.2 472.8 320 464 320H112C103.2 320 96 327.2 96 336C96 344.8 103.2 352 112 352zM376 160C362.7 160 352 170.7 352 184V232C352 245.3 362.7 256 376 256H456C469.3 256 480 245.3 480 232V184C480 170.7 469.3 160 456 160H376z"/></svg> */}
          <i className="fa-solid fa-money-check"></i>
          {`  Cheque Management`}
        </Link>
        <div className="sidebar-user">
          <img
            src={avtar}
            className="img-fluid rounded-circle mb-2"
            alt="Admin"
          />
          <div className="fw-bold">Imageio Admin</div>
          <small>Admin</small>
        </div>

        <div className="divScrollMenu">
          <Routes>
            <Route path="/*" element={<AppSideMenu />} />
          </Routes>
        </div>
      </div>
    </nav>
  );
};

export default AppSideBar;
