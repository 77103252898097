import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";
import {
  ChequeEntries,
  UpdateChequeStatus,
  Vendor,
  ChequeTransaction,
  ChequeTransactionPayable,
  VendorEntry,
  Dashboard,
  Reports,
  Users,
  UserMaster,
  Settings,
  Role
} from "../components";
import AppHeaderTop from "./AppHeaderTop";
import AppSideBar from "./AppSideBar";
import { commonActions } from "../redux/actions";

const AuthLayout = () => {
  const [toggled, setToggled] = useState(false);
  const dispatch = useDispatch<any>();

  useEffect(() => {
    dispatch(commonActions.get_geoLocations());
  });

  return (
    // <div className="container">
    //   <button className="btn btn-primary">Test</button>
    //   <AppSidebar />
    //   <div className="wrapper d-flex flex-column min-vh-100 bg-light">
    //     <AppHeader />
    //     <div className="body flex-grow-1 px-3">
    //       <AppContent />
    //     </div>
    //     <AppFooter />
    //   </div>
    // </div>
    <div className="innerWrapper">
      <AppSideBar isToggled={toggled} />
      <div
        className={
          toggled
            ? "be-content container-fluid toggledIn"
            : "be-content container-fluid"
        }
      >
        <div className="main">
          <AppHeaderTop
            toggleClick={() => setToggled(!toggled)}
            isToggled={toggled}
          />
          <main className="content">
            <Routes>
              <Route path="/" element={<Dashboard />} />
              <Route path="/vendor" element={<Vendor />} />
              <Route path="/users" element={<Users/>} />
              <Route path="/role" element={<Role />} />
              <Route path="/users/add" element={<UserMaster/>} />
              <Route path="/vendor/add" element={<VendorEntry />} />
              <Route path="/vendor/:id" element={<VendorEntry />} />
              <Route path="/cheque" element={<ChequeEntries />} />
              <Route path="/settings" element={<Settings />} />
              <Route
                path="/cheque/receivables"
                element={<ChequeTransaction isReceivable={1} />}
              />
              <Route
                path="/cheque/receivables/:id"
                element={<ChequeTransaction isReceivable={1} />}
              />
              <Route
                path="/cheque/payables"
                element={<ChequeTransactionPayable isReceivable={0} />}
              />
              <Route
                path="/cheque/payables/:id"
                element={<ChequeTransactionPayable isReceivable={0} />}
              />
              <Route
                path="/cheque/update-status"
                element={<UpdateChequeStatus />}
              />
              <Route
                path="/reports/date_wise_payable_receivable"
                element={<Reports.PayableReceivable />}
              />
              <Route
                path="/reports/overdue_cheque_details"
                element={<Reports.OverdueCheques />}
              />
              <Route
                path="/reports/vendor_cheque_details"
                element={<Reports.VendorChequeDetails />}
              />
            
            </Routes>
          </main>
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;
