import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

import moment from "moment";


interface UserData {
  Data: any;
  Address: any;
  open: boolean;
  onClose: () => void;
}

const Modals = (props: UserData) => {
  const { Address, Data, open, onClose } = props;
  return (
    <Modal
      open={open}
      onClose={onClose}
      center
      styles={{ closeButton: { display: "none" } }}
    >
      <>
        <button
          className="btn btn-success btn-md d-print-none"
          type="button"
          onClick={() => window.print()}
          style={{
            margin: "20px 0px 5px 0px",
            padding: "4px 20px",
            float: "right"
          }}
        >
          Print
        </button>

        <table
          className="table table-responsive "
          style={{ border: "2px solid", fontSize: "15px" }}
        >
          <tr>
            <td
              colSpan={4}
              style={{ textAlign: "center", fontWeight: "bolder" }}
            >
              <h5> Customer/Vendor Details</h5>
            </td>
          </tr>

          <tr>
            <td className="text-center text-bold">Company Name</td>{" "}
            <td colSpan={3} className="padding-left">
              {Data.companyName}
            </td>
          </tr>
          <tr>
            <td className="text-center text-bold">Ownership Type</td>{" "}
            <td colSpan={3} className="padding-left">
              {Data.ownershipType}
            </td>
          </tr>
          <tr>
            <td className="text-center text-bold">Full Name </td>{" "}
            <td
              colSpan={3}
              className="padding-left"
            >{`${Data.firstName} ${Data.middleName} ${Data.lastName}`}</td>
          </tr>
          <tr>
            <td className="text-center text-bold">Birth Date </td>{" "}
            <td colSpan={3} className="padding-left">
              {moment(Data.birthDate).format("DD/MM/YYYY")}
            </td>
          </tr>

          <tr>
            <td className="text-center text-bold">Aadhar Number </td>{" "}
            <td colSpan={3} className="padding-left">
              {Data.aadhar}
            </td>
          </tr>
          <tr>
            <td className="text-center text-bold">PAN Number </td>{" "}
            <td colSpan={3} className="padding-left">
              {Data.aadhar}
            </td>
          </tr>
          <tr>
            <td className="text-center text-bold">Email </td>{" "}
            <td colSpan={3} className="padding-left">
              {Data.email}{" "}
            </td>
          </tr>
          <tr>
            <td colSpan={4} className="text-center text-bold">
              {" "}
              Address Details
            </td>
          </tr>
          {Address.firstName !== "" &&
            Address.address.map((i: any, index: number) => (
              <>
                <tr>
                  <td className="text-center text-bold"> Address {++index} </td>{" "}
                  <td colSpan={3} className="padding-left">
                    {i.address}
                  </td>
                </tr>
                <tr>
                  <td className="text-center text-bold">Address Type </td>
                  <td className="text-center text-bold">State </td>
                  <td className="text-center text-bold">City </td>
                  <td className="text-center text-bold">Pincode </td>
                </tr>
                <tr>
                  <td className="text-center">{i.addressType}</td>
                  <td className="text-center">{i.state}</td>
                  <td className="text-center">{i.city}</td>
                  <td className="text-center">{i.pincode}</td>
                </tr>
              </>
            ))}

          <tr>
            <td colSpan={4} className="text-center text-bold">
              {" "}
              Bank Details
            </td>
          </tr>

          {Address.firstName !== "" &&
            Address.bankDetails.map((i: any) => (
              <>
                <tr>
                  <td className="text-center text-bold">Bank Name </td>{" "}
                  <td colSpan={3} className="padding-left">
                    {i.bankName}
                  </td>
                </tr>
                <tr>
                  <td className="text-center text-bold">Branch Name </td>
                  <td className="text-center text-bold">
                    Name on Bank Account{" "}
                  </td>
                  <td className="text-center text-bold">Account No. </td>
                  <td className="text-center text-bold">IFSC Code </td>
                </tr>
                <tr>
                  <td className="text-center">{i.branchName}</td>
                  <td className="text-center">{i.accountName}</td>
                  <td className="text-center">{i.accountNo}</td>
                  <td className="text-center">{i.ifscCode}</td>
                </tr>
              </>
            ))}

          <tr>
            <td colSpan={4} className="text-center text-bold">
              Document Details
            </td>
          </tr>
          {Address.firstName !== "" &&
            Address.documentDetails.map((i: any) => (
              <>
                <tr>
                  <td className="text-center text-bold">Document Name</td>
                  <td colSpan={3} className="text-center text-bold">
                    file Name
                  </td>
                </tr>
                <tr>
                  <td className="text-center">{i.documentName}</td>{" "}
                  <td colSpan={3} className="padding-left">
                    {i.fileName}
                  </td>
                </tr>
              </>
            ))}
        </table>

        <button
          type="button"
          className="btn btn-danger  btn-sm d-print-none"
          onClick={onClose}
          style={{ float: "right" }}
        >
          <i className="fa-solid fa-xmark"></i> {" Cancel"}
        </button>

        <style>
          {
            "\
          .text-center{\
            text-align:center;\
          }\
          .text-bold{\
            font-weight:bold;\
          }\
          .padding-left{\
            padding-left:10px;\
          }\
        tr{\
          border:2px solid black;\
          padding:4px;\
        }\
        td{\
          border-right:2px solid black;\
          padding:4px;\
        }\
        @media print { .navbar-theme{display:none;} }\
      "
          }{" "}
        </style>
      </>
    </Modal>
  );
};

export default Modals;
