import { Actions } from ".";
import { SelectedOption } from "../../components/Vendor/BankDetails";
import { chequeTypes } from "../constTypes";

export type Cheque = {
    cheque_entries_list: ChequeEntriesList[],
    cheque_status_master: SelectedOption[]
}

export type ChequeEntriesList = {
    id: number;
    isReceivable: number;
    isReceivableName: string;
    name: string;
    bankName: string;
    chequeDate: string;
    chequeNumber: string;
    chequeAmount: number;
    status: string;
    remarks: string;
    email: string;
    mobile: string;
    paymentType?: string;

}
const initialState: Cheque = {
    cheque_entries_list: [],
    cheque_status_master: []
};

export const cheque = (state = initialState, action: Actions) => {

    switch (action.type) {
        case chequeTypes.CHEQUE_ENTRY_LIST:
            return {
                ...state,
                cheque_entries_list: action.payload
            }
        case chequeTypes.CHEQUE_STATUS_MASTER:
            return {
                ...state,
                cheque_status_master: action.payload
            }
        default:
            return state;
    }
};

