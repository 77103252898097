/* eslint-disable react-hooks/exhaustive-deps */
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { AgGridReact } from "ag-grid-react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { vendorActions } from "../../redux/actions";
import { useAppSelector } from "../../redux/store/hooks";
import GridActions from "../common/GridActions";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-tabs/style/react-tabs.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { toast } from "react-toastify";
import "react-responsive-modal/styles.css";
import Modals from "./Modals";

const Vendor = () => {
  const gridRef = useRef() as MutableRefObject<AgGridReact> | null;
  const dispatch = useDispatch<any>();
  const { vendors, vendor_details_by_id } = useAppSelector((obj) => obj);
  const [rowData, setRowData] = useState([]);
  const navigate = useNavigate();
  // const { id } = useParams()
  const [open, setOpen] = useState(false);

  interface UserData {
    companyName?: string;
    ownershipType?: string;
    firstName?: string;
    middleName?: string;
    lastName?: string;
    aadhar?: string;
    PAN?: string;
    email?: string;
    mobile?: string;
    birthDate?: Date;
  }

  const [Data, setData] = useState<UserData>({});
  const [Ids, setIds] = useState(1);

  const onGridEdit = (val: any) => {
    navigate(`/app/vendor/${val.data.id}`);
  };

  const handleView = (val: any) => {
    setOpen(true);
    setData(val.data);
    setIds(val.data.id);
  };

  const onCloseModal = () => {
    setOpen(false);
  };
  const onGridDelete = (val: any) => {
    confirmAlert({
      title: "Delete Confirmation",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          //@ts-ignore
          onClick: () => {
            dispatch(
              vendorActions.deleteVendor(
                val.data.id,
                () => {
                  dispatch(vendorActions.getVendors());
                  toast.success("Vendor deleted successfully");
                },
                (err: any) => {
                  toast.error(err.toString());
                }
              )
            );
          }
        },
        {
          label: "No",
          //@ts-ignore
          onClick: () => {}
        }
      ]
    });
  };
  const [columnDefs] = useState([
    { field: "companyName", headerName: "Company" },
    { field: "ownershipType", headerName: "Ownership Type" },
    { field: "firstName", headerName: "First Name" },
    { field: "middleName", headerName: "Middle Name" },
    { field: "lastName", headerName: "Last Name" },
    { field: "aadhar", headerName: "Aadhar #" },
    { field: "PAN", headerName: "PAN" },
    { field: "mobile", headerName: "Mobile #" },
    {
      headerName: "Actions",
      pinned: "right",
      cellRendererFramework: (val: any) => (
        <GridActions
          data={val}
          onEditClick={onGridEdit}
          onDeleteClick={onGridDelete}
          viewButton={true}
          onViewClick={handleView}
        />
      ),
      field: "id",
      colId: "params",
      suppressFilter: true,
      enableSorting: false
    }
  ]);

  const defaultColDef = useMemo(() => {
    return {
      filter: true,
      floatingFilter: true,
      resizable: true
    };
  }, []);

  useEffect(() => {
    dispatch(vendorActions.getVendors());
  }, []);
  useEffect(() => {
    setRowData(vendors);
  }, [vendors]);

  useEffect(() => {
    dispatch(vendorActions.getVendorDetailsById(Ids));
  }, [Ids]);

  const onFirstDataRendered = useCallback((params: any) => {
    gridRef?.current.api.sizeColumnsToFit();
  }, []);

  return (
    <div className="container-fluid">
      <h1 className="header-title">Customer/Vendor Master</h1>
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12 pb-1 float-right d-flex flex-row-reverse">
          <Link to={"/app/vendor/add"} className="btn btn-success btn-sm">
            <i className="fa-solid fa-user-plus"></i>
            {" Add New Customer/Vendor"}
          </Link>
        </div>

        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="card">
            <div className="card-body">
              <div className="ag-theme-balham agGrid-height-width">
                <AgGridReact
                  ref={gridRef}
                  rowData={rowData}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  onFirstDataRendered={onFirstDataRendered}
                ></AgGridReact>
              </div>

              <Modals
                Data={Data}
                Address={vendor_details_by_id}
                open={open}
                onClose={onCloseModal}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vendor;
