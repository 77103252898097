import * as yup from "yup";

export const personalDetailsSchema = yup.object({
    firstName: yup.string().required("First Name is required."),
    lastName: yup.string().required("Last Name is required."),
    PAN: yup.string().required("PAN is required."),
    aadhar: yup.string().required("Aadhar is required.").length(12,"Aadhar number must be of 12 digit"),
    email: yup.string().required("Email is required.").email("Invalid Email format"),
    mobile: yup.string().required("Mobile is required.").min(10, "Phone number must be atleast 10 digits"),
})

export const addressDetailsSchema = yup.object({
    address: yup.string().required("Address is required."),
    state: yup.string().ensure().required("State is required."),
    city: yup.string().required("City is required."),
    pincode: yup.string().required("Pincode is required."),
})

export const bankDetailsSchema = yup.object({
    accountNo: yup.string().required("Account no is required"),
    ifscCode: yup.string().required("IFSC Code is required."),
    accountName: yup.string().required("Account Name is required."),
})