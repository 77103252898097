/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  MutableRefObject,
  useEffect
} from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
//@ts-ignore
import FileBase64 from "react-file-base64";
import { bankDetailsSchema } from "./validationSchema";
import { useAppSelector } from "../../redux/store/hooks";
import { AgGridReact } from "ag-grid-react";
import GridActions from "../common/GridActions";

import { keys } from "../../config/keys";
import { confirmAlert } from "react-confirm-alert";
import { Callbacks, commonActions, vendorActions } from "../../redux/actions";
import { useDispatch } from "react-redux";

export type DocumentDetailsProps = {
  //  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setDocumentDetails?: React.Dispatch<any>;
  documentDetails?: VendorDocumentDetails[] | undefined;
  setDeletedIds?: React.Dispatch<any>;
};
export const DocumentDetails = ({
  setDocumentDetails,
  documentDetails,
  setDeletedIds
}: DocumentDetailsProps) => {
  const gridRef = useRef() as MutableRefObject<AgGridReact> | null;
  const [rowData, setRowData] = useState<VendorDocumentDetails[] | undefined>(
    [...documentDetails!] || []
  );
  const dispatch = useDispatch<any>();
  const { documents } = useAppSelector((obj) => obj);
  const { control, trigger } = useForm({
    mode: "all",
    // reValidateMode: "onChange",
    resolver: yupResolver(bankDetailsSchema)
  });

  const [formData, setFormData] = useState<VendorDocumentDetails>({
    documentId: documents[0].value,
    documentName: documents[0].label
  });

  const [selectedDocument, setSelectedDocument] =
    useState<SelectedOption | null>(documents[0]);
  const [files] = useState<any>([]);
  const [docs, setDocs] = useState<any>([]);

  const onGridEdit = (val: any) => {
    setSelectedDocument(documents.find((x) => x.value === val.data.documentId));
    setFormData(val.data);
  };

  const onGridDelete = (val: any) => {
    confirmAlert({
      title: "Delete Confirmation",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          //@ts-ignore
          onClick: () => {
            setRowData((prev: any) => [
              ...prev.filter((x: VendorDocumentDetails) => x.id !== val.value)
            ]);

            dispatch(
              vendorActions.deleteDocument(val.value),
              () => {
                setRowData((prev: any) => [
                  ...prev.filter(
                    (x: VendorDocumentDetails) => x.id !== val.value
                  )
                ]);

                setDeletedIds && setDeletedIds(val.value);
              },
              (err: any) => {
                console.log("ERROR", err);
              }
            );
          }
        },
        {
          label: "No",
          //@ts-ignore
          onClick: () => {}
        }
      ]
    });
  };

  const [columnDefs] = useState([
    { field: "documentName", headerName: "Document" },
    { field: "fileName", headerName: "FileName" },
    {
      headerName: "Actions",
      pinned: "right",
      cellRenderer: (val: any) => (
        <GridActions
          data={val}
          showEditButton={false}
          onDeleteClick={onGridDelete}
          onEditClick={onGridEdit}
          showViewButton
        />
      ),
      field: "id",
      colId: "params"
    }
  ]);

  const defaultColDef = useMemo(() => {
    return {
      filter: true,
      floatingFilter: true,
      resizable: true
    };
  }, []);

  const onFirstDataRendered = useCallback((params: any) => {
    gridRef?.current.api.sizeColumnsToFit();
  }, []);

  const onAdd = async (e: any) => {
    trigger();
    const data = { ...formData, id: Math.random() * 100 + 1 };
    setRowData((prev: any) => [...prev, { ...data }]);
  };

  useEffect(() => {
    trigger();
  }, []);
  useEffect(() => {
    setDocumentDetails && setDocumentDetails(rowData);
    const doc: any[] = [];
    rowData?.map((x) => {
      return doc.push({ uri: `${keys().ServicePath}/${x.filePath}` });
    });
    setDocs(doc);
  }, [rowData]);

  return (
    // <form onSubmit={handleSubmit(onSubmit)} noValidate>
    <div className="row">
      <div className="col">
        <div className="row g-2">
          <div className="col-md-12 col-lg-12 col-sm-12">
            <label className="form-label">Bank</label>
            <Controller
              name={"bank"}
              control={control}
              render={({ field }) => (
                <CreatableSelect
                  {...field}
                  className="react-select"
                  value={selectedDocument}
                  options={documents}
                  onChange={(option: any) => {
                    setSelectedDocument(option);
                    setFormData((prev) => ({
                      ...prev,
                      documentId: option.value,
                      documentName: option.label
                    }));
                  }}
                  onCreateOption={(inputValue: string) => {
                    const doc = {
                      document: inputValue
                    };
                    console.log(doc);
                    try {
                      dispatch(
                        commonActions.add_document_list(
                          doc,
                          (val: any) => {
                            setSelectedDocument(val[val.length - 1]);
                            setFormData((prev) => ({
                              ...prev,
                              documentId: val[val.length - 1].value,
                              documentName: val[val.length - 1].label
                            }));
                          },
                          (err: any) => {
                            console.log(err);
                          }
                        )
                      );
                    } catch (ee) {
                      console.log(ee);
                    }
                  }}
                />
              )}
            />
          </div>
          <div className="col-md-12 col-lg-12 col-sm-12">
            <label className="form-label">Upload</label>
            <div className="file-input">
              <FileBase64
                multiple={false}
                onDone={(file: any) => {
                  setFormData((prev: VendorDocumentDetails) => ({
                    ...prev,
                    file: file.base64,
                    fileName: file.name
                  }));
                  // setDocs((prev:any)=>([...prev,{uri: file.base64 }]));
                }}
                className="btn btn-success btn-sm"
              />
            </div>

            {/* <FilePond
            files={files}
            onupdatefiles={(file: any) => {
              setFiles(file);
              setFormData((prev: VendorDocumentDetails) => ({
                ...prev,
                file: file && file.length > 0 ? file[0].file : null,
                fileName: file && file.length > 0 ? file[0].file.name : ""
              }));
            }}
            allowMultiple={false}
            // server="/api"
            name="files"
            labelIdle='Drag & Drop your photo or <span class="filepond--label-action">Browse</span>'
          /> */}
          </div>

          <div className="col-lg-12 col-md-12 col-sm-12">
            <button
              type="button"
              className="btn btn-success btn-sm"
              onClick={onAdd}
            >
              <i className="fa-solid fa-plus"></i>
              {" Add"}
            </button>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="card">
              <div className="card-body">
                <div className="ag-theme-balham agGrid-height-width">
                  <AgGridReact
                    ref={gridRef}
                    rowData={rowData}
                    columnDefs={columnDefs}
                    defaultColDef={defaultColDef}
                    onFirstDataRendered={onFirstDataRendered}
                  ></AgGridReact>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="col">
        <DocViewer documents={docs} pluginRenderers={DocViewerRenderers}/>
      </div> */}
    </div>
  );
};

export type VendorDocumentDetails = {
  id?: number | undefined;
  vendorId?: number | undefined;
  documentId?: number | undefined;
  documentName: string | undefined;
  fileName?: string | undefined;
  filePath?: string | undefined;
  file?: any;
};

export type SelectedOption = {
  value: string | number;
  label: string;
};
