import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  MutableRefObject,
  useEffect
} from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { confirmAlert } from "react-confirm-alert"; // Import
import Select from "react-select";
import { addressDetailsSchema } from "./validationSchema";
import { Cities } from "../../redux/reducers/common";
import { useAppSelector } from "../../redux/store/hooks";
import { AgGridReact } from "ag-grid-react";
import GridActions from "../common/GridActions";
import { useDispatch } from "react-redux";
import { vendorActions } from "../../redux/actions";

export type AddressDetailsProps = {
  // addressFormData?: AddressDetailsProps;
  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setAddressFormData?: React.Dispatch<any>;
  address?: VendorAddressDetails[] | undefined;
  // errors: any;
  // register: UseFormRegister<FieldValues>;
};
export const AddressDetails = ({
  setAddressFormData,
  address
}: AddressDetailsProps) => {
  const gridRef = useRef() as MutableRefObject<AgGridReact> | null;
  const [rowData, setRowData] = useState<VendorAddressDetails[] | undefined>(
    [...address!] || []
  );

  const dispatch = useDispatch<any>();
  const { geoLocation } = useAppSelector((obj) => obj);
  const {
    register,
    setValue,
    formState: { errors },
    control,
    trigger,
    reset
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(addressDetailsSchema)
  });

  const [formData, setFormData] = useState<VendorAddressDetails>({
    address: "",
    state: "",
    city: "",
    pincode: "",
    addressType: geoLocation.houseType[0].label
  });

  const [selectedStateOption, setSelectedStateOption] =
    useState<SelectedOption | null>(null);
  const [selectedCityOption, setSelectedCityOption] =
    useState<SelectedOption | null>(null);
  const [selectedHouseType, setSelectedHouseType] =
    useState<SelectedOption | null>(geoLocation.houseType[0]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const onGridEdit = (val: any) => {
    setSelectedStateOption(
      geoLocation?.states?.find((x) => x.value === val.data.stateId)!
    );
    setSelectedCityOption(
      geoLocation?.cities?.find((x) => x.value === val.data.cityId)!
    );
    setFormData(val.data);
  };

  const onGridDelete = (val: any) => {
    console.log(val);
    confirmAlert({
      title: "Delete Confirmation",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          //@ts-ignore
          onClick: () => {
            dispatch(
              vendorActions.deleteAddress(
                val.value,
                () => {
                  setRowData((prev: any) => [
                    ...prev.filter(
                      (x: VendorAddressDetails) => x.id !== val.value
                    )
                  ]);
                  console.log("success");
                },
                (err: any) => {
                  console.log("ERROR", err);
                }
              )
            );
          }
        },
        {
          label: "No",
          //@ts-ignore
          onClick: () => {}
        }
      ]
    });
  };

  const [columnDefs] = useState([
    { field: "addressType", headerName: "Address Type" },
    { field: "address", headerName: "Address" },
    { field: "state", headerName: "State" },
    { field: "city", headerName: "City" },
    { field: "pincode", headerName: "Pincode" },
    {
      headerName: "Actions",
      pinned: "right",
      cellRenderer: (val: any) => (
        <GridActions
          data={val}
          showEditButton={false}
          onDeleteClick={onGridDelete}
          onEditClick={onGridEdit}
        />
      ),
      field: "id",
      colId: "params"
    }
  ]);

  const defaultColDef = useMemo(() => {
    return {
      filter: true,
      floatingFilter: true,
      resizable: true
    };
  }, []);

  const onFirstDataRendered = useCallback((params: any) => {
    gridRef?.current.api.sizeColumnsToFit();
  }, []);

  const onAdd = (e: any) => {
    if (Object.entries(errors).length > 0) return;
    setRowData((prev: any) => [
      ...prev,
      { ...formData, id: Math.random() * 100 + 1 }
    ]);
    setFormData((prev) => ({
      ...prev,
      address: "",
      pincode: ""
    }));
    setSelectedStateOption(null);
    setSelectedCityOption(null);
    reset({
      address: "",
      pincode: "",
      addressType: geoLocation.houseType[0].label
    });
    trigger();
  };

  useEffect(() => {
    setAddressFormData && setAddressFormData(rowData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rowData]);

  useEffect(() => {
    trigger();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    // <form onSubmit={handleSubmit(onSubmit)} noValidate>
    <>
      <div className="row">
        <div className="col-md-3 col-lg-3 col-sm-12 mb-2">
          <label className="form-label">Address Type</label>
          <Controller
            name={"addressType"}
            control={control}
            render={({ field }) => (
              <Select
                {...register("addressType")}
                {...field}
                value={selectedHouseType}
                options={geoLocation.houseType}
                onChange={(option: any) => {
                  setSelectedHouseType(option);
                  setFormData((prev) => ({
                    ...prev,
                    addressType: option.label
                  }));
                }}
              />
            )}
          />
        </div>
        <div className="col-md-12 col-lg-12 col-sm-12 mb-2">
          <label className="form-label">Address</label>
          <textarea
            {...register("address")}
            className={`form-control ${errors?.address ? `error` : ``} `}
            name={"address"}
            placeholder="Address"
            onChange={handleChange}
            value={formData.address}
          />
          {errors?.address && (
            <div className="invalid-feedback">{errors?.address?.message}</div>
          )}
        </div>
        <div className="col-md-3 col-lg-3 col-sm-12 mb-2">
          <label className="form-label">State</label>
          <Controller
            name={"state"}
            control={control}
            render={({ field }) => (
              <Select
                {...register("state")}
                {...field}
                className="react-select"
                value={selectedStateOption}
                options={geoLocation.states}
                onChange={(option: any) => {
                  setSelectedStateOption(option);
                  setFormData((prev) => ({
                    ...prev,
                    state: option.label,
                    stateId: option.value as number
                  }));
                  setValue("state", option.value);
                }}
              />
            )}
          />
          {errors?.state && (
            <div className="invalid-feedback">{errors?.state?.message}</div>
          )}
        </div>
        <div className="col-md-3 col-lg-3 col-sm-12 mb-2">
          <label className="form-label">City</label>
          <Controller
            name={"city"}
            control={control}
            render={({ field }) => (
              <Select
                {...register("city")}
                {...field}
                className="react-select"
                value={selectedCityOption}
                onChange={(option: any) => {
                  setSelectedCityOption(option);
                  setValue("city", option.value);
                  setFormData((prev) => ({
                    ...prev,
                    city: option.label,
                    cityId: option.value
                  }));
                }}
                options={
                  selectedStateOption === null
                    ? geoLocation.cities
                    : geoLocation.cities.filter(
                        (x: Cities) => x.state_id === selectedStateOption.value
                      )
                }
              />
            )}
          />
          {errors?.city && (
            <div className="invalid-feedback">{errors?.city?.message}</div>
          )}
        </div>

        <div className="col-md-3 col-lg-3 col-sm-12 mb-2">
          <label className="form-label">Pincode</label>
          <input
            {...register("pincode")}
            className={`form-control ${errors?.pincode ? `error` : ``} `}
            name={"pincode"}
            placeholder="Pincode"
            onChange={handleChange}
            value={formData.pincode}
          />
          {errors?.pincode && (
            <div className="invalid-feedback">{errors?.pincode?.message}</div>
          )}
        </div>

        <div className="col-lg-12 col-md-12 col-sm-12">
          <button
            type="button"
            className="btn btn-success btn-sm"
            onClick={onAdd}
          >
            <i className="fa-solid fa-plus"></i>
            {" Add"}
          </button>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="card">
            <div className="card-body">
              <div className="ag-theme-balham agGrid-height-width">
                <AgGridReact
                  ref={gridRef}
                  rowData={rowData}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  onFirstDataRendered={onFirstDataRendered}
                ></AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
    // </form>
  );
};

export type VendorAddressDetails = {
  id?: number | undefined;
  address: string | undefined;
  state?: string | undefined;
  city?: string | undefined;
  stateId?: number | undefined;
  cityId?: number | undefined;
  addressType?: number | string | undefined;
  pincode?: string | undefined;
};
export type VendorAddressDetailsKeys = {
  address: "address";
  state: "state";
  city: "city";
  pincode: "pincode";
};

export type SelectedOption = {
  value: string | number;
  label: string;
};
