/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { chequeActions } from "../../redux/actions";
import { ChequeEntriesList } from "../../redux/reducers/cheque";
import { useAppSelector } from "../../redux/store/hooks";
import { SelectedOption } from "../Vendor/AddressDetails";
import { transactionUpdateSchema } from "./validationSchema";

export const UpdateChequeStatus = () => {
  const dispatch = useDispatch<any>();
  const {
    cheque: { cheque_entries_list, cheque_status_master }
  } = useAppSelector((obj) => obj);
  const formatter = new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR"
  });

  const initialData: ChequeStatusTransactionType = {
    chequeMasterId: 0,
    chequeStatusId: 0,
    remarks: "",
    isCurrentStatus: 1
  };

  const [formData, setFormData] =
    useState<ChequeStatusTransactionType>(initialData);

  const [selectedChequeNumber, setSelectedChequeNumber] = useState<
    (ChequeEntriesList & SelectedOption) | null
  >(null);

  const [selectedStatus, setSelectedStatus] = useState<SelectedOption | null>(
    null
  );

  const [chequeTransactionHistory, setChequeTransactionHistory] = useState<
    ChequeStatusTransactionType[]
  >([]);

  const {
    register,
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    reset
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(transactionUpdateSchema)
  });

  useEffect(() => {
    dispatch(
      chequeActions.get_cheque_enties_list({
        onError: (err) => toast.error(err.toString())
      })
    );

    dispatch(chequeActions.get_cheque_status_master({}));
  }, []);

  useEffect(() => {
    
    if (selectedChequeNumber) {
      dispatch(
        chequeActions.get_cheque_transaction_history(selectedChequeNumber.id, {
          onSuccess: (res) => {
            setChequeTransactionHistory(res.data.rows);
          },
          onError: (err) => {
            toast.error(err.toString());
          }
        })
      );
    }
  }, [selectedChequeNumber]);

  const resetData = () => {
    setSelectedChequeNumber(null);
    setChequeTransactionHistory([]);
    setValue("chequeMasterId", null);
    setValue("chequeStatusId", null);
    reset(initialData);
  };

  const onSubmit = (data: any, e: any) => {
    e.preventDefault();
    dispatch(
      chequeActions.update_cheque_staus(formData, {
        onSuccess: (res) => {
          toast.success("Status Updated successfully.");
          resetData();
        },
        onError: (err) => {
          toast.error(err.toString());
        }
      })
    );
  };

  return (
    <div className="container-fluid">
      <h1 className="header-title">{`Update Cheque Status`}</h1>

      <div className="card">
        <div className="card-header">
          <h5 className="card-title">Cheque Status Updates</h5>
          <h6 className="card-subtitle text-muted">
            {`Update status of the cheques`}
          </h6>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="row g-2 mb-2">
              <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12 ">
                <label className="form-label">{`Cheque Number`}</label>

                <Controller
                  name={"chequeMasterId"}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...register("chequeMasterId")}
                      {...field}
                      className={`react-select ${
                        errors?.chequeMasterId ? `error` : ``
                      }`}
                      value={selectedChequeNumber}
                      options={cheque_entries_list.map(
                        (x: ChequeEntriesList) => {
                          const opt: ChequeEntriesList & SelectedOption = {
                            value: x.id,
                            label: x.chequeNumber,
                            ...x
                          };
                          return opt;
                        }
                      )}
                      onChange={(option: any) => {
                        setSelectedChequeNumber(
                          option as ChequeEntriesList & SelectedOption
                        );
                        setValue("chequeMasterId", option.value);
                        setFormData((prev) => ({
                          ...prev,
                          chequeMasterId: option.value,
                          chequeDetails: option
                        }));
                      }}
                    />
                  )}
                />
                {errors?.chequeMasterId && (
                  <div className="invalid-feedback">
                    {errors?.chequeMasterId?.message}
                  </div>
                )}
              </div>
              <div className="col-md-9 col-lg-9 col-sm-12 col-xs-12 ">
                {selectedChequeNumber && (
                  <table className="table table-sm table-responsive table-bordered table-warning">
                    <thead>
                      <tr>
                        <th scope="col">Vendor</th>
                        <th scope="col">Cheque Date</th>
                        <th scope="col">Cheque Amount</th>
                        <th scope="col">Cheque Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{selectedChequeNumber.name}</td>
                        <td>{selectedChequeNumber.chequeDate}</td>
                        <td>
                          {formatter.format(selectedChequeNumber.chequeAmount)}
                        </td>
                        <td>{selectedChequeNumber?.isReceivableName}</td>
                      </tr>
                    </tbody>
                  </table>
                  // <div className="alert alert-info" role="alert">
                  //   <div>
                  //     <h6 className="alert-heading">{`Cheque Details - ${selectedChequeNumber?.isReceivableName}`}</h6>
                  //     <div className="d-flex flex-column bd-highlight mb-3">
                  //       <label>{selectedChequeNumber.name}</label>
                  //       <label>{selectedChequeNumber.chequeDate}</label>
                  //       <label>
                  //         {formatter.format(selectedChequeNumber.chequeAmount)}
                  //       </label>
                  //     </div>
                  //   </div>
                  // </div>
                )}
              </div>
              <div className="col-12">
                {chequeTransactionHistory.length > 0 && (
                  <table className="table table-bordered table-responsive table-sm caption-top">
                    <caption>
                      {`Status history for Cheque # - ${selectedChequeNumber?.chequeNumber}`}{" "}
                    </caption>
                    <thead>
                      <tr>
                        <th>Cheque Status</th>
                        <th>Status Updated On</th>
                        <th>Remarks</th>
                      </tr>
                    </thead>
                    <tbody>
                      {chequeTransactionHistory.map((item) => (
                        <tr>
                          <td>{item.status}</td>
                          <td>{item.statusUpdatedate}</td>
                          <td>{item.remarks}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
              <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12 ">
                <label className="form-label">{`Cheque Status`}</label>
                <Controller
                  name={"chequeStatusId"}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...register("chequeStatusId")}
                      {...field}
                      className={`react-select ${
                        errors?.chequeStatusId ? `error` : ``
                      }`}
                      value={selectedStatus}
                      options={cheque_status_master}
                      onChange={(option: any) => {
                        setSelectedStatus(option as SelectedOption);
                        setValue("chequeStatusId", option.label);
                        setFormData((prev) => ({
                          ...prev,
                          chequeStatusId: option.value,
                          status: option.label
                        }));
                      }}
                    />
                  )}
                />
                {errors?.chequeStatusId && (
                  <div className="invalid-feedback">
                    {errors?.chequeStatusId?.message}
                  </div>
                )}
              </div>
              <div className="col-md-9 col-lg-9 col-sm-12 col-xs-12 ">
                <label className="form-label">{`Additional Remarks`}</label>
                <textarea
                  rows={3}
                  className={`form-control`}
                  name="remarks"
                  onChange={(e) =>
                    setFormData((prev) => ({
                      ...prev,
                      remarks: e.target.value
                    }))
                  }
                  value={formData.remarks}
                />
              </div>
            </div>
            <div className="row g-2 mb-2">
              <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12">
                <button
                  type="submit"
                  className="btn btn-success w-100 btn-sm"
                  // style={{ marginRight: 2 }}
                >
                  <i className="fa-solid fa-floppy-disk"></i> {" Save"}
                </button>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12">
                <button
                  type="button"
                  className="btn btn-danger w-100 btn-sm"
                  onClick={() => resetData()}
                  // style={{ marginRight: 2 }}
                >
                  <i className="fa-solid fa-xmark"></i> {" Cancel"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateChequeStatus;

export type ChequeStatusTransactionType = {
  id?: number;
  chequeMasterId: number;
  chequeStatusId: number;
  status?: string;
  statusUpdatedate?: string;
  remarks?: string;
  isCurrentStatus?: number;
  chequeDetails?: ChequeEntriesList;
};
