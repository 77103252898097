/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, FieldValues, UseFormRegister } from "react-hook-form";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "react-datepicker/dist/react-datepicker.css";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { VendorAddressDetails } from "./AddressDetails";
import { VendorBankDetails } from "./BankDetails";
import { VendorDocumentDetails } from "./DocumentDetails";
import ReactSelect from "react-select";
import { useAppSelector } from "../../redux/store/hooks";
import { useDispatch } from "react-redux";
import { commonActions } from "../../redux/actions";
import { Options } from "../../redux/reducers/common";

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

export type PersonalDetailProps = {
  formData: VendorPersonlDetails;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setFormData: React.Dispatch<any>;
  errors: any;
  register: UseFormRegister<FieldValues>;
  files: any;
  setFiles: React.Dispatch<any>;
  reset: any;
  control: any;
};
export const PersonalDetails = (props: PersonalDetailProps) => {
  const {
    formData,
    handleChange = () => {},
    setFormData,
    errors,
    register,
    files,
    setFiles,
    control
  } = props;

  const dispatch = useDispatch<any>();
  const { businessTypes } = useAppSelector((obj) => obj);

  const [selectedBusinessType, setSelectedBusinessType] =
    useState<Options | null>(null);

  useEffect(() => {
    dispatch(commonActions.get_business_type());
  }, []);

  useEffect(() => {
    if (formData.id && businessTypes) {
      setSelectedBusinessType(
        businessTypes.filter((x) => x.value === formData.ownershipTypeId)[0]
      );
    }
  }, [businessTypes, formData.id]);

  return (
    // <form onSubmit={handleSubmit(onSubmit)} noValidate>
    <div className="row">
      <div className="col-md-6 col-lg-6 col-sm-12 mb-2">
        <label className="form-label">Company Name</label>
        <input
          className={`form-control`}
          name={"companyName"}
          placeholder="Company Name"
          onChange={handleChange}
          defaultValue={formData.companyName}
          value={formData.companyName}
        />
      </div>

      <div className="col-md-4 col-lg-4 col-sm-12 mb-2">
        <label className="form-label">Type</label>

        <Controller
          name={"state"}
          control={control}
          render={({ field }) => (
            <ReactSelect
              {...field}
              className="react-select"
              value={selectedBusinessType}
              options={businessTypes}
              onChange={(option: any) => {
                setSelectedBusinessType(option);
                setFormData((prev: VendorPersonlDetails) => ({
                  ...prev,
                  ownershipTypeId: option.value as number
                }));
                // setValue("state", option.value);
              }}
            />
          )}
        />
      </div>

      <div className="col-md-3 col-lg-3 col-sm-12 mb-2">
        <label className="form-label">First Name</label>
        <input
          {...register("firstName")}
          className={`form-control ${errors?.firstName ? `error` : ``} `}
          name={"firstName"}
          placeholder="First Name"
          onChange={handleChange}
          defaultValue={formData.firstName}
          value={formData.firstName}
        />
        {errors?.firstName && (
          <div className="invalid-feedback">{errors?.firstName?.message}</div>
        )}
      </div>
      <div className="col-md-3 col-lg-3 col-sm-12 mb-2">
        <label className="form-label">Middle Name</label>
        <input
          className="form-control"
          name={"middleName"}
          placeholder="Middle Name"
          onChange={handleChange}
          value={formData.middleName}
        />
      </div>
      <div className="col-md-3 col-lg-3 col-sm-12 mb-2">
        <label className="form-label">Last Name</label>
        <input
          {...register("lastName")}
          className={`form-control ${errors?.lastName ? `error` : ``} `}
          name={"lastName"}
          placeholder="Last Name"
          onChange={handleChange}
          value={formData.lastName}
        />
        {errors?.lastName && (
          <div className="invalid-feedback">{errors?.lastName?.message}</div>
        )}
      </div>

      <div className="col-md-3 col-lg-3 col-sm-12 mb-2">
        <label className="form-label">Aadhar #</label>
        <input
          {...register("aadhar")}
          className={`form-control ${errors?.aadhar ? `error` : ``} `}
          name={"aadhar"}
          placeholder="Aadhar #"
          onChange={handleChange}
          onKeyPress={(event) => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          value={formData.aadhar}
        />
        {errors?.aadhar && (
          <div className="invalid-feedback">{errors?.aadhar?.message}</div>
        )}
      </div>

      <div className="col-md-3 col-lg-3 col-sm-12 mb-2">
        <label className="form-label">PAN #</label>
        <input
          {...register("PAN")}
          className={`form-control ${errors?.PAN ? `error` : ``} `}
          name={"PAN"}
          placeholder="PAN #"
          onChange={handleChange}
          value={formData.PAN}
        />
        {errors?.PAN && (
          <div className="invalid-feedback">{errors?.PAN?.message}</div>
        )}
      </div>

      <div className="col-md-3 col-lg-3 col-sm-12 mb-2">
        <label className="form-label">Birth Date</label>

        <DatePicker
          {...register("birthDate")}
          selected={formData?.birthDate as Date}
          onChange={(date: Date) =>
            setFormData((prev: VendorPersonlDetails) => ({
              ...prev,
              birthDate: date
            }))
          }
          showYearDropdown
          dateFormat={"dd/MM/yyyy"}
          className={`form-control ${errors?.birthDate ? `error` : ``} `}
        />
        {errors?.birthDate && (
          <div className="invalid-feedback">{errors?.birthDate?.message}</div>
        )}
      </div>

      <div className="col-md-3 col-lg-3 col-sm-12 mb-2">
        <label className="form-label">Email</label>

        <input
          {...register("email")}
          className={`form-control ${errors?.email ? `error` : ``} `}
          name={"email"}
          placeholder="Email"
          onChange={handleChange}
          value={formData.email}
        />
        {errors?.email && (
          <div className="invalid-feedback">{errors?.email?.message}</div>
        )}
      </div>

      <div className="col-md-3 col-lg-3 col-sm-12 mb-2">
        <label className="form-label">Mobile No.</label>

        <input
          {...register("mobile")}
          className={`form-control ${errors?.mobile ? `error` : ``} `}
          name={"mobile"}
          placeholder="Mobile #"
          onChange={handleChange}
          value={formData.mobile}
        />
        {errors?.mobile && (
          <div className="invalid-feedback">{errors?.mobile?.message}</div>
        )}
      </div>

      <div className="col-md-3 col-lg-3 col-sm-12 mb-2">
        <label className="form-label">Photo</label>
        <FilePond
          files={files}
          onupdatefiles={(file) => {
            setFiles(file);
            setFormData((prev: VendorPersonlDetails) => ({
              ...prev,
              photo: file && file.length > 0 ? file[0].file : null
            }));
          }}
          allowMultiple={false}
          // server="/api"
          name="files"
          labelIdle='Drag & Drop your photo or <span class="filepond--label-action">Browse</span>'
        />
      </div>

      {/* <div className="col-lg-12 col-md-12 col-sm-12">
        <button type="submit" className="btn btn-success">
          Save
        </button>
      </div> */}
    </div>
    // </form>
  );
};

export type Vendor = {
  personalDetails: VendorPersonlDetails;
  personalDetailsKeys: VendorPersonlDetailsKeys;
};

export type VendorPersonlDetails = {
  id?: number | undefined;
  companyName?: string | undefined;
  ownershipTypeId?: number | undefined;
  ownershipType?: string | undefined;
  firstName: string | undefined;
  middleName?: string | undefined;
  lastName: string | undefined;
  aadhar: string | undefined;
  PAN: string | undefined;
  birthDate: Date | string | undefined;
  email: string | undefined;
  mobile: string | undefined;
  photoPath?: string | undefined;
  photo?: any | undefined;
  fyId?: number | undefined;
  address?: VendorAddressDetails[] | undefined;
  bankDetails?: VendorBankDetails[] | undefined;
  documentDetails?: VendorDocumentDetails[] | undefined;
  documentFiles?: any[] | undefined;
  deletedDocumentIds?: string[] | number[] | undefined;
};

export type VendorDropDown = {
  value?: number | string | undefined;
  label?: string | undefined;
  aadhar?: string | undefined;
  PAN?: string | undefined;
  email?: string | undefined;
  mobile?: string | undefined;
  photoPath?: string | undefined;
};

export type VendorPersonlDetailsKeys = {
  firstName: "firstName";
  middleName: "middleName";
  lastName: "lastName";
  aadhar: "aadhar";
  PAN: "PAN";
  birthDate: "birthDate";
  email: "email";
  mobile: "mobile";
};
