export const GET_GEOLOCATION_LIST = "GET_GEOLOCATION_LIST";
export const GET_STATES = "GET_STATES";
export const GET_CITIES = "GET_CITIES";

export const ADD_STATUS = "ADD_STATUS";
export const GET_BANKS = "GET_BANKS";
export const GET_DOCUMENTS = "GET_DOCUMENTS";
export const GET_ROLE_MASTER = "GET_ROLE_MASTER";
export const GET_BUSINESS_TYPE = "GET_BUSINESS_TYPE";

export const GET_ROLES = "GET_ROLES";