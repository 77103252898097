import axios from "../../components/common/axios";
import { toast } from "react-toastify";
import { ADD_STATUS, GET_BANKS, GET_BUSINESS_TYPE, GET_CITIES, GET_DOCUMENTS, GET_ROLE_MASTER, GET_STATES } from "../constTypes/common";
import { keys } from "../../config/keys";

const _addCities = (data: any) => {
    return {
        type: GET_CITIES,
        payload: data,
    };
};

const _addStates = (data: any) => {
    return {
        type: GET_STATES,
        payload: data,
    };
};

export const _addStatusUpdate = (data: any) => {
    return {
        type: ADD_STATUS,
        payload: data,
    };
};

export const _bankList = (data: any) => {
    return {
        type: GET_BANKS,
        payload: data,
    };
};

const _documentList = (data: any) => {
    return {
        type: GET_DOCUMENTS,
        payload: data,
    };
};

const _roleMaster = (data: any) => {
    return {
        type: GET_ROLE_MASTER,
        payload: data,
    };
};

export const get_geoLocations = () => async (dispatch: any) => {
    await axios({
        method: "get",
        url: `${keys().ServicePath}/api/common/get_geolocations`,
    }).then((res) => {
        dispatch(_addCities(res.data.rows[1]));
        dispatch(_addStates(res.data.rows[0]));
    }).catch((e) => {
        toast.error(e.toString())
    });
}

export const get_bank_list = () => async (dispatch: any) => {
    await axios({
        method: "get",
        url: `${keys().ServicePath}/api/common/get_bank_list`,
    }).then((res) => {
        dispatch(_bankList(res.data.rows));
    }).catch((e) => {
        toast.error(e.toString())
    });
}

export const get_document_list = () => async (dispatch: any) => {
    await axios({
        method: "get",
        url: `${keys().ServicePath}/api/common/get_document_list`,
    }).then((res) => {
        dispatch(_documentList(res.data.rows));
    }).catch((e) => {
        toast.error(e.toString())
    });
}

export const get_role_master = (callbacks?: Callbacks) => async (dispatch: any) => {
    await axios({
        method: "get",
        url: `${keys().ServicePath}/api/common/get_role_master`,
    }).then((res) => {
        dispatch(_roleMaster(res.data.rows));
        if (callbacks?.onSuccess) callbacks.onSuccess(res.data.rows);
    }).catch((e) => {
        toast.error(e.toString())
    });
}


const _businessType = (data: any) => {
    return {
        type: GET_BUSINESS_TYPE,
        payload: data,
    };
};

export const get_business_type = (callbacks?: Callbacks) => async (dispatch: any) => {
    await axios({
        method: "get",
        url: `${keys().ServicePath}/api/common/get_business_type`,
    }).then((res) => {
        dispatch(_businessType(res.data.rows));
        if (callbacks?.onSuccess) callbacks.onSuccess(res.data.rows);
    }).catch((e) => {
        toast.error(e.toString())
    });
}

export const add_document_list = (data: any, onSuccess?: any, onError?: any) => async (dispatch: any) => {
    console.log("DATA", data)
    await axios({
        method: "post",
        url: `${keys().ServicePath}/api/masters/add_document_master`,
        data: { ...data }
    }).then((res) => {
        dispatch(_documentList(res.data.rows[1]));
        console.log(res.data)
        if (onSuccess) onSuccess(res.data.rows[1]);
    }).catch((e) => {
        if (onError) onError(e);
        toast.error(e.toString())
    });
}

export type Callbacks = {
    onSuccess?: (val: any) => void;
    onError?: (val: any) => void;
}