import { useAuth } from "../App";

/* eslint-disable jsx-a11y/anchor-is-valid */
type AppHeaderProps = {
  toggleClick?: () => void;
  isToggled?: boolean;
};
const AppHeaderTop = (props: AppHeaderProps) => {
  const { toggleClick = () => {}, isToggled = false } = props;
  let auth = useAuth();

  const logout = () => {
    localStorage.clear();
    sessionStorage.clear();
    auth.signout(() => {
      window.location.href = "/";
    });
  };
  return (
    <nav
      className={
        isToggled
          ? "navbar-theme navbar-expand navbar navToggledIn"
          : "navbar-theme navbar-expand navbar"
      }
    >
      <div
        className="headerAcadDropdown"
        //onMouseLeave={() => this.setState({ showhideacadeYear: false })}
      >
        <span className="sidebar-toggle" onClick={() => toggleClick()}>
          <i className="hamburger" />
        </span>
      </div>
      <form className="d-none d-sm-inline-block">
        <input
          className="form-control form-control-lite"
          type="text"
          placeholder="Search..."
        />
      </form>
      <div className="navbar-collapse collapse">
        <ul className="navbar-nav ms-auto">
          <li className="nav-item dropdown active">
            <a
              className="nav-link dropdown-toggle position-relative"
              href="#"
              id="messagesDropdown"
              data-bs-toggle="dropdown"
            >
              <i className="align-middle fas fa-envelope-open"></i>
            </a>
          </li>
          <li className="nav-item dropdown ms-lg-2">
            <a
              className="nav-link dropdown-toggle position-relative"
              href="#"
              id="alertsDropdown"
              data-bs-toggle="dropdown"
            >
              <i className="align-middle fas fa-bell"></i>
              <span className="indicator"></span>
            </a>
          </li>
          <li className="nav-item dropdown ms-lg-2">
            <a
              className="nav-link dropdown-toggle position-relative"
              href="#"
              id="userDropdown"
              data-bs-toggle="dropdown"
            >
              <i className="align-middle fas fa-cog"></i>
            </a>
            <div
              className="dropdown-menu dropdown-menu-end right-align"
              aria-labelledby="userDropdown"
            >
              <a className="dropdown-item" href="#">
                <i className="align-middle me-1 fas fa-fw fa-user"></i> View
                Profile
              </a>
              <a className="dropdown-item" href="#">
                <i className="align-middle me-1 fas fa-fw fa-comments"></i>{" "}
                Contacts
              </a>
              <a className="dropdown-item" href="#">
                <i className="align-middle me-1 fas fa-fw fa-chart-pie"></i>{" "}
                Analytics
              </a>
              <a className="dropdown-item" href="#">
                <i className="align-middle me-1 fas fa-fw fa-cogs"></i> Settings
              </a>
              <div className="dropdown-divider"></div>
              <a className="dropdown-item" href="#" onClick={() => logout()}>
                <i className="align-middle me-1 fas fa-fw fa-arrow-alt-circle-right"></i>{" "}
                Sign out
              </a>
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default AppHeaderTop;
