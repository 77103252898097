/* eslint-disable react-hooks/exhaustive-deps */
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { AgGridReact } from "ag-grid-react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { vendorActions } from "../../redux/actions";
import { useAppSelector } from "../../redux/store/hooks";
import GridActions from "../common/GridActions";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-tabs/style/react-tabs.css";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import { toast } from "react-toastify";

const User = () => {
  const gridRef = useRef() as MutableRefObject<AgGridReact> | null;
  const dispatch = useDispatch<any>();
  const { vendors } = useAppSelector((obj) => obj);
  const [rowData, setRowData] = useState([]);
  const navigate = useNavigate();

  const onGridEdit = (val: any) => {
    navigate(`/app/vendor/${val.data.id}`);
  };

  const onGridDelete = (val: any) => {
    confirmAlert({
      title: "Delete Confirmation",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          //@ts-ignore
          onClick: () => {
            dispatch(
              vendorActions.deleteVendor(
                val.data.id,
                () => {
                  dispatch(vendorActions.getVendors());
                  toast.success("Vendor deleted successfully");
                },
                (err: any) => {
                  toast.error(err.toString());
                }
              )
            );
          }
        },
        {
          label: "No",
          //@ts-ignore
          onClick: () => {}
        }
      ]
    });
  };
  const [columnDefs] = useState([
    { field: "firstName", headerName: "First Name" },
    { field: "middleName", headerName: "Middle Name" },
    { field: "lastName", headerName: "Last Name" },
    { field: "aadhar", headerName: "Aadhar #" },
    { field: "PAN", headerName: "PAN" },
    { field: "mobile", headerName: "Mobile #" },
    {
      headerName: "Actions",
      pinned: "right",
      cellRendererFramework: (val: any) => (
        <GridActions
          data={val}
          onEditClick={onGridEdit}
          onDeleteClick={onGridDelete}
        />
      ),
      field: "id",
      colId: "params",
      suppressFilter: true,
      enableSorting: false
    }
  ]);

  const defaultColDef = useMemo(() => {
    return {
      filter: true,
      floatingFilter: true,
      resizable: true
    };
  }, []);

  useEffect(() => {
    dispatch(vendorActions.getVendors());
  }, []);
  useEffect(() => {
    setRowData(vendors);
  }, [vendors]);

  const onFirstDataRendered = useCallback((params: any) => {
    gridRef?.current.api.sizeColumnsToFit();
  }, []);

  return (
    <div className="container-fluid">
      <h1 className="header-title">User Master</h1>
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12 pb-1 float-right d-flex flex-row-reverse">
          <Link to={"/app/users/add"} className="btn btn-success btn-sm">
            <i className="fa-solid fa-user-plus"></i>
            {" Add New User"}
          </Link>
        </div>
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="card">
            <div className="card-body">
              <div className="ag-theme-balham agGrid-height-width">
                <AgGridReact
                  ref={gridRef}
                  rowData={rowData}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  onFirstDataRendered={onFirstDataRendered}
                ></AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default User;
