/* eslint-disable no-labels */
/* eslint-disable @typescript-eslint/no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  MutableRefObject
} from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import { reportActions } from "../../redux/actions";
import { useAppSelector } from "../../redux/store/hooks";
import ReactDatePicker from "react-datepicker";
import { SideBarDef } from "ag-grid-community";
import { defaultColDefParams } from "../common/agGridCommon";
import { PayableReceivableType } from ".";

const OverdueCheques = () => {
  const gridRef = useRef() as MutableRefObject<AgGridReact> | null;
  const dispatch = useDispatch<any>();
  const {
    cheque: { cheque_entries_list }
  } = useAppSelector((obj) => obj);
  const [rowData, setRowData] = useState([]);

  const initialData: PayableReceivableType = {
    fromDate: new Date(),
    toDate: new Date()
  };

  const [formData, setFormData] = useState<PayableReceivableType>(initialData);

  const [columnDefs] = useState([
    { field: "name", headerName: "Name", width: 200 },
    { field: "bankName", headerName: "Bank", width: 200 },
    {
      field: "chequeDate",
      headerName: "Cheque Date",
      filter: "agDateColumnFilter"
    },
    { field: "chequeNumber", headerName: "Cheque #", width: 120 },
    { field: "chequeAmount", headerName: "Amount", enableValue: true },

    {
      field: "isReceivableName",
      headerName: "Receivable/Payable",
      filter: "agSetColumnFilter",
      enableRowGroup: true,
      rowGroup: true,
      hide: true
    }
  ]);

  const defaultColDef = useMemo(() => {
    return {
      ...defaultColDefParams
    };
  }, []);

  useEffect(() => {
    dispatch(reportActions.overdue_cheque_details(formData));
  }, []);

  useEffect(() => {
    setRowData(cheque_entries_list);
  }, [cheque_entries_list]);

  const rowClassRules = useMemo(() => {
    return {
      overdue: 'data.status==="Overdue"',
      cleared: 'data.status==="Cleared"'
    };
  }, []);

  return (
    <div className="container-fluid">
      <h1 className="header-title">{`Overdue Cheque details`}</h1>

      <div className="card">
        <div className="card-header">
          <h5 className="card-title">Overdue Cheque details</h5>
          <h6 className="card-subtitle text-muted">
            {`List of cheques whose due date has been passed`}
          </h6>
        </div>
        <div className="card-body">
          <div className="row mb-2 g-2">
            <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12 d-none">
              <label className="form-label">From Date</label>

              <ReactDatePicker
                selected={formData?.fromDate as Date}
                onChange={(date: Date) =>
                  setFormData((prev: PayableReceivableType) => ({
                    ...prev,
                    fromDate: date
                  }))
                }
                showYearDropdown
                dateFormat={"dd/MM/yyyy"}
                className={`form-control`}
              />
            </div>
            <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12 d-none">
              <label className="form-label">To Date</label>

              <ReactDatePicker
                selected={formData?.toDate as Date}
                onChange={(date: Date) =>
                  setFormData((prev: PayableReceivableType) => ({
                    ...prev,
                    toDate: date
                  }))
                }
                showYearDropdown
                dateFormat={"dd/MM/yyyy"}
                className={`form-control`}
              />
            </div>
            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12 d-flex align-items-end d-none">
              <button
                type="button"
                className="btn btn-success w-100 btn-sm"
                onClick={() =>
                  dispatch(reportActions.overdue_cheque_details(formData))
                }
                // style={{ marginRight: 2 }}
              >
                <i className="fa-solid fa-file-contract"></i> {" View"}
              </button>
            </div>
          </div>
          <div className="ag-theme-balham agGrid-height-width">
            <AgGridReact
              // ref={gridRef}
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              // onFirstDataRendered={onFirstDataRendered}
              // rowClassRules={rowClassRules}
              groupDefaultExpanded={1}
              groupDisplayType={"singleColumn"}
              showOpenedGroup={true}
              animateRows={true}
            ></AgGridReact>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverdueCheques;
