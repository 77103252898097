/* eslint-disable no-restricted-globals */
import axios from "../../components/common/axios";
import { toast } from "react-toastify";
import { VendorDropDown, VendorPersonlDetails } from "../../components/Vendor/PersonalDetails";
import { VENDOR_DETAILS, VENDOR_DROPDOWN, VENDOR_LIST } from "../constTypes/vendor";
import { keys } from "../../config/keys";

export const saveVendor = (data: VendorPersonlDetails, onSuccess?: any, onError?: any) => async (dispatch: any) => {
    const form = new FormData();
    Object.entries(data).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            form.append(key, JSON.stringify(value));
        }
        else { form.append(key, value); }
    });
    await axios({
        method: "post",
        url: `${keys().ServicePath}/api/vendor/save`,
        data: form,
        headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
        // dispatch(_addStatusUpdate({ ...res.data.rows[0], "type": "vendor" }));
        // location.href = "/app/vendor";

        toast.success("Added successfully!!!");
        if (onSuccess) onSuccess(res);
    }).catch((e) => {

        toast.error(e.toString());
        if (onError) onError(e);
    });

}

export const deleteVendor = (id: number, onSuccess?: any, onError?: any) => async (dispatch: any) => {

    await axios.delete(`/api/vendor/${id}`).then(res => {
        if (onSuccess) onSuccess(res)
    }).catch(err => {
        if (onError) onError(err);
    })
}

export const deleteAddress = (id: number, onSuccess?: any, onError?: any) => async (dispatch: any) => {

    await axios.delete(`/api/vendor/address/${id}`).then(res => {
        if (onSuccess) onSuccess(res)
    }).catch(err => {
        if (onError) onError(err);
    })
}

export const deleteBank = (id: number, onSuccess?: any, onError?: any) => async (dispatch: any) => {

    await axios.delete(`/api/vendor/bank/${id}`).then(res => {
        if (onSuccess) { onSuccess(res) }
    }).catch(err => {
        if (onError) { onError(err); }
    })
}

export const deleteDocument = (id: number, onSuccess?: any, onError?: any) => async (dispatch: any) => {

    await axios.delete(`/api/vendor/document/${id}`).then(res => {
        if (onSuccess) onSuccess(res)
    }).catch(err => {
        if (onError) onError(err);
    })
}

export const updateVendor = (data: VendorPersonlDetails, onSuccess?: any, onError?: any) => async (dispatch: any) => {
    const form = new FormData();
    Object.entries(data).forEach(([key, value]) => {
        if (Array.isArray(value)) {
            form.append(key, JSON.stringify(value));
        }
        else { form.append(key, value); }
    });

    await axios({
        method: "patch",
        url: `${keys().ServicePath}/api/vendor`,
        data: form,
        headers: { "Content-Type": "multipart/form-data" },
    }).then((res) => {
        // dispatch(_addStatusUpdate({ ...res.data.rows[0], "type": "vendor" }));
        // location.href = "/app/vendor";
        toast.success("Updated successfully!!!")
        if (onSuccess) onSuccess(res);
    }).catch((e) => {
        toast.error(e.toString());
        if (onError) onError(e);
    });

}

const _vendorList = (data: any) => {
    return {
        type: VENDOR_LIST,
        payload: data,
    };
};

export const getVendors = () => async (dispatch: any) => {

    await axios.get(`${keys().ServicePath}/api/vendor`).then((res) => {

        dispatch(_vendorList(res.data.rows))

    }).catch((e) => {
        toast.error(e.toString())
    });


}

const _vendor_details_by_id = (data: any) => {
    return {
        type: VENDOR_DETAILS,
        payload: data,
    };
};

export const getVendorDetailsById = (id: number) => async (dispatch: any) => {

    await axios.get(`${keys().ServicePath}/api/vendor/${id}`).then((res) => {

        const data: VendorPersonlDetails = res.data.rows[0][0];
        data.birthDate = new Date(res.data.rows[0][0].birthDate);
        data.address = res.data.rows[1];
        data.bankDetails = res.data.rows[2];
        data.documentDetails = res.data.rows[3]
        //const data = res.data.rows[0]
        dispatch(_vendor_details_by_id(data))
    }).catch((e) => {
        toast.error(e.toString())
    });


}

const _vendor_dropdown = (data: any) => {
    return {
        type: VENDOR_DROPDOWN,
        payload: data,
    };
};

export const getVendorDropDownList = () => async (dispatch: any) => {

    await axios.get(`${keys().ServicePath}/api/vendor/vendor_dropdown_list`).then((res) => {
        const data: VendorDropDown[] = res.data.rows;
        dispatch(_vendor_dropdown(data))
    }).catch((e) => {
        toast.error(e.toString())
    });


}