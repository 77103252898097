/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import { yupResolver } from "@hookform/resolvers/yup";
import { userMasterSchema } from "./validationSchema";
import { Options } from "../../redux/reducers/common";
import { useAppSelector } from "../../redux/store/hooks";
import { useDispatch } from "react-redux";
import { commonActions } from "../../redux/actions";

const UserMaster = () => {
  const dispatch = useDispatch<any>();
  const { roles } = useAppSelector((obj) => obj);
  const [selectedRole, setSelectedRole] = useState<Options | null>(null);
  const [formData, setFormData] = useState<UserMasterType>({
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phone: "",
    username: "",
    password: "",
    roleId: 0
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    reset
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(userMasterSchema)
  });

  const onSubmit = (data: any, e: any) => {
    e.preventDefault();
  };

  useEffect(() => {
    dispatch(
      commonActions.get_role_master({
        onSuccess: (res) => {
          setSelectedRole(res[0]);
        }
      })
    );
  }, []);

  return (
    <div className="container-fluid">
      <h1 className="header-title">User Master</h1>
      <div className="card">
        <div className="card-header">
          <h5 className="card-title">User Master</h5>
          <h6 className="card-subtitle text-muted">{`Create new users`}</h6>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className="row mb-2">
              <div className="col-md-3 col-lg-3 col-sm-12 mb-2">
                <label className="form-label">First Name</label>
                <input
                  {...register("firstName")}
                  className={`form-control ${
                    errors?.firstName ? `error` : ``
                  } `}
                  name={"firstName"}
                  placeholder="First Name"
                  onChange={handleChange}
                  defaultValue={formData.firstName}
                  value={formData.firstName}
                />
                {errors?.firstName && (
                  <div className="invalid-feedback">
                    {errors?.firstName?.message}
                  </div>
                )}
              </div>
              <div className="col-md-3 col-lg-3 col-sm-12 mb-2">
                <label className="form-label">Middle Name</label>
                <input
                  className="form-control"
                  name={"middleName"}
                  placeholder="Middle Name"
                  onChange={handleChange}
                  value={formData.middleName}
                />
              </div>
              <div className="col-md-3 col-lg-3 col-sm-12 mb-2">
                <label className="form-label">Last Name</label>
                <input
                  className={`form-control`}
                  name={"lastName"}
                  placeholder="Last Name"
                  onChange={handleChange}
                  value={formData.lastName}
                />
              </div>

              <div className="col-md-3 col-lg-3 col-sm-12 mb-2">
                <label className="form-label">Email</label>

                <input
                  {...register("email")}
                  className={`form-control ${errors?.email ? `error` : ``} `}
                  name={"email"}
                  placeholder="Email"
                  onChange={handleChange}
                  value={formData.email}
                />
                {errors?.email && (
                  <div className="invalid-feedback">
                    {errors?.email?.message}
                  </div>
                )}
              </div>

              <div className="col-md-3 col-lg-3 col-sm-12 mb-2">
                <label className="form-label">Mobile No.</label>

                <input
                  {...register("phone")}
                  className={`form-control ${errors?.phone ? `error` : ``} `}
                  name={"phone"}
                  placeholder="Mobile #"
                  onChange={handleChange}
                  value={formData.phone}
                />
                {errors?.mobile && (
                  <div className="invalid-feedback">
                    {errors?.phone?.message}
                  </div>
                )}
              </div>

              <div className="col-md-3 col-lg-3 col-sm-12 mb-2">
                <label className="form-label">Role</label>

                <Controller
                  name={"roleId"}
                  control={control}
                  render={({ field }) => (
                    <Select
                      {...register("roleId")}
                      {...field}
                      value={selectedRole}
                      options={roles}
                      className={`react-select`}
                      onChange={(option: any) => {
                        setSelectedRole(option);
                        setFormData((prev) => ({
                          ...prev,
                          roleName: option.label
                        }));
                      }}
                    />
                  )}
                />
              </div>
            </div>
            <div className="row g-2">
              <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12">
                <button
                  type="submit"
                  className="btn btn-success w-100 btn-sm"
                  // style={{ marginRight: 2 }}
                >
                  <i className="fa-solid fa-floppy-disk"></i> {" Save"}
                </button>
              </div>
              <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12">
                <button
                  type="button"
                  className="btn btn-danger w-100 btn-sm"
                  onClick={() => reset()}
                  // style={{ marginRight: 2 }}
                >
                  <i className="fa-solid fa-xmark"></i> {" Cancel"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserMaster;

export type UserMasterType = {
  id?: number;
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  phone: string;
  roleId: number;
  roleName?: string;
  username: string;
  password: string;
};
