import { Actions } from ".";
import { VendorDropDown, VendorPersonlDetails } from "../../components/Vendor/PersonalDetails";
import * as types from "../constTypes";

const initialState = {};
const Vendor_details: VendorPersonlDetails = {
    firstName: "",
    lastName: "",
    PAN: "",
    aadhar: "",
    birthDate: new Date(),
    email: "",
    mobile: ""
};

const vendor_dropdown_list: VendorDropDown[] = [];

export const vendors = (state = initialState, action: Actions) => {

    switch (action.type) {
        case types.vendorTypes.VENDOR_LIST:
            return action.payload || []
        default:
            return state;
    }
};

export const vendor_details_by_id = (state = Vendor_details, action: Actions) => {

    switch (action.type) {
        case types.vendorTypes.VENDOR_DETAILS:
            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
};

export const vendor_dropdown = (state = vendor_dropdown_list, action: Actions) => {

    switch (action.type) {
        case types.vendorTypes.VENDOR_DROPDOWN:
            return action.payload;
        default:
            return state;
    }
};

