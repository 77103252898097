/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { AddressDetails } from "./AddressDetails";
import { PersonalDetails, VendorPersonlDetails } from "./PersonalDetails";
import { format } from "date-fns";
import { useDispatch } from "react-redux";
import { commonActions, vendorActions } from "../../redux/actions";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { personalDetailsSchema } from "./validationSchema";
import { Link, useNavigate, useParams } from "react-router-dom";
import { BankDetails } from "./BankDetails";
import { DocumentDetails } from "./DocumentDetails";
import { useAppSelector } from "../../redux/store/hooks";
import { keys } from "../../config/keys";

const VendorEntry = () => {
  const dispatch = useDispatch<any>();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [files, setFiles] = useState<any>([]);
  const { id } = useParams();
  const { vendor_details_by_id } = useAppSelector((obj) => obj);
  const [formData, setFormData] = useState<VendorPersonlDetails>({
    firstName: "",
    middleName: "",
    lastName: "",
    aadhar: "",
    PAN: "",
    birthDate: new Date(),
    email: "",
    mobile: "",
    photoPath: "",
    address: [],
    bankDetails: [],
    documentDetails: []
  });
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (status && status.type === "vendor") {
  //     if (status.insertId > 0) {
  //       navigate("/app/vendor");
  //       dispatch(_addStatusUpdate(undefined));
  //     }
  //   }
  // }, [status.insertId]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(personalDetailsSchema)
  });

  const onSubmit = (data: any, e: any) => {
    e.preventDefault();
    if (id) {
      console.log(formData);

      dispatch(
        vendorActions.updateVendor(
          {
            ...formData,
            address: formData.address?.filter((x) => x.id! % 1 !== 0),
            bankDetails: formData.bankDetails?.filter((x) => x.id! % 1 !== 0),
            birthDate: format(formData.birthDate as Date, "yyyy-MM-dd")
          },
          () => {
            navigate("/app/vendor");
          }
        )
      );
    } else {
      dispatch(
        vendorActions.saveVendor(
          {
            ...formData,
            birthDate: format(formData.birthDate as Date, "yyyy-MM-dd")
          },
          () => {
            navigate("/app/vendor");
          }
        )
      );
    }
  };

  useEffect(() => {
    dispatch(commonActions.get_bank_list());
    dispatch(commonActions.get_document_list());
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(vendorActions.getVendorDetailsById(parseInt(id)));
    }
  }, [id]);

  useEffect(() => {
    if (id && vendor_details_by_id) {
      setFormData(vendor_details_by_id);
      if (vendor_details_by_id?.photoPath !== "") {
        setFiles([
          {
            source: `${keys().ServicePath}/${vendor_details_by_id.photoPath}`,
            options: {
              type: "remote"
            }
          }
        ]);
      }
      reset(vendor_details_by_id);
    }
  }, [vendor_details_by_id]);

  return (
    <div className="container-fluid">
      <h1 className="header-title">Customer Master</h1>
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12 mx-auto">
          <div className="card">
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)} noValidate>
                <Tabs
                  className={"customTabs customInnerTabs"}
                  selectedIndex={selectedIndex}
                  onSelect={(index) => setSelectedIndex(index)}
                >
                  <TabList>
                    <Tab>Personal Details</Tab>
                    <Tab>Address Details</Tab>
                    <Tab>Bank Details</Tab>
                    <Tab>Documents</Tab>
                  </TabList>

                  <TabPanel>
                    <PersonalDetails
                      formData={formData}
                      handleChange={handleChange}
                      setFormData={setFormData}
                      errors={errors}
                      register={register}
                      files={files}
                      setFiles={setFiles}
                      reset={reset}
                      control={control}
                    />
                  </TabPanel>
                  <TabPanel>
                    <AddressDetails
                      address={formData.address}
                      setAddressFormData={(addressData) =>
                        setFormData((prev) => ({
                          ...prev,
                          address: addressData
                        }))
                      }
                    />
                  </TabPanel>
                  <TabPanel>
                    <BankDetails
                      bankDetails={formData.bankDetails}
                      setBankDetails={(bankData) =>
                        setFormData((prev) => ({
                          ...prev,
                          bankDetails: bankData
                        }))
                      }
                    />
                  </TabPanel>
                  <TabPanel>
                    <DocumentDetails
                      documentDetails={formData.documentDetails}
                      setDocumentDetails={(documentData) =>
                        setFormData((prev) => ({
                          ...prev,
                          documentDetails: documentData
                        }))
                      }
                      setDeletedIds={(deletedId) => {
                        setFormData((prev) => ({
                          ...prev,
                          deletedDocumentIds: [
                            ...prev.deletedDocumentIds!,
                            deletedId
                          ]
                        }));
                      }}
                    />
                  </TabPanel>
                </Tabs>
                <div className="row g-2">
                  <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12">
                    <button
                      type="submit"
                      className="btn btn-success w-100 btn-sm"
                      // style={{ marginRight: 2 }}
                    >
                      <i className="fa-solid fa-floppy-disk"></i> {" Save"}
                    </button>
                  </div>
                  <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-xs-12">
                    <Link
                      to={"/app/vendor"}
                      className="btn btn-danger w-100 btn-sm"
                    >
                      <i className="fa-solid fa-xmark"></i>
                      {" Cancel"}
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VendorEntry;
