/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { dashboardActions } from "../../redux/actions";
import { useAppSelector } from "../../redux/store/hooks";
import { MonthsOverview } from "../../redux/reducers/dashboard";
import { keys } from "../../config/keys";

const Dashboard = () => {
  const dispatch = useDispatch<any>();

  const {
    dashboard: { months_overview }
  } = useAppSelector((obj) => obj);

  useEffect(() => {
    dispatch(dashboardActions.get_months_overview({}));
  }, []);

  return (
    <div className="container-fluid">
      <div className="header">
        <h1 className="header-title">Overview</h1>
        <p className="header-subtitle">
          Your sales increased by 4.25% and revenue increased by 5.12%.
        </p>
      </div>

      <div className="row">
        {months_overview.map((item: MonthsOverview) => (
          <div className="col-md-6 col-lg-3 col-xl">
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col mt-0">
                    <h5 className="card-title">{`${item.month} - ${item.paymentType}`}</h5>
                  </div>

                  <div className="col-auto">
                    <div className="avatar">
                      <div className="avatar-title rounded-circle bg-primary-dark">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-dollar-sign align-middle"
                        >
                          <line x1="12" y1="1" x2="12" y2="23"></line>
                          <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <h1 className="display-5 mt-1 mb-3">
                  {keys().formatter.format(item.chequeAmount)}{" "}
                </h1>
                <div className="mb-0">
                  <span className="text-danger">
                    {" "}
                    <i className="mdi mdi-arrow-bottom-right"></i> -2.65%{" "}
                  </span>
                  Less sales than usual
                </div>
              </div>
            </div>
          </div>
        ))}
{/* 
        <div className="col-md-6 col-lg-3 col-xl">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col mt-0">
                  <h5 className="card-title">Orders</h5>
                </div>

                <div className="col-auto">
                  <div className="avatar">
                    <div className="avatar-title rounded-circle bg-primary-dark">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-shopping-cart align-middle"
                      >
                        <circle cx="9" cy="21" r="1"></circle>
                        <circle cx="20" cy="21" r="1"></circle>
                        <path d="M1 1h4l2.68 13.39a2 2 0 0 0 2 1.61h9.72a2 2 0 0 0 2-1.61L23 6H6"></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <h1 className="display-5 mt-1 mb-3">12.514</h1>
              <div className="mb-0">
                <span className="text-success">
                  {" "}
                  <i className="mdi mdi-arrow-bottom-right"></i> 5.50%{" "}
                </span>
                More visitors than usual
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3 col-xl">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col mt-0">
                  <h5 className="card-title">Activity</h5>
                </div>

                <div className="col-auto">
                  <div className="avatar">
                    <div className="avatar-title rounded-circle bg-primary-dark">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-activity align-middle"
                      >
                        <polyline points="22 12 18 12 15 21 9 3 6 12 2 12"></polyline>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <h1 className="display-5 mt-1 mb-3">29.232</h1>
              <div className="mb-0">
                <span className="text-danger">
                  {" "}
                  <i className="mdi mdi-arrow-bottom-right"></i> -4.25%{" "}
                </span>
                More earnings than usual
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-lg-3 col-xl">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col mt-0">
                  <h5 className="card-title">Revenue</h5>
                </div>

                <div className="col-auto">
                  <div className="avatar">
                    <div className="avatar-title rounded-circle bg-primary-dark">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-dollar-sign align-middle"
                      >
                        <line x1="12" y1="1" x2="12" y2="23"></line>
                        <path d="M17 5H9.5a3.5 3.5 0 0 0 0 7h5a3.5 3.5 0 0 1 0 7H6"></path>
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <h1 className="display-5 mt-1 mb-3">$83.300</h1>
              <div className="mb-0">
                <span className="text-success">
                  {" "}
                  <i className="mdi mdi-arrow-bottom-right"></i> 8.35%{" "}
                </span>
                More earnings than usual
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Dashboard;
