/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  MutableRefObject,
  useEffect
} from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Select from "react-select";
import { bankDetailsSchema } from "./validationSchema";
import { useAppSelector } from "../../redux/store/hooks";
import { AgGridReact } from "ag-grid-react";
import GridActions from "../common/GridActions";
import { confirmAlert } from "react-confirm-alert";
import { vendorActions } from "../../redux/actions";
import { useDispatch } from "react-redux";

export type BankDetailsProps = {
  //  handleChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setBankDetails?: React.Dispatch<any>;
  bankDetails?: VendorBankDetails[] | undefined;
};
export const BankDetails = ({
  setBankDetails,
  bankDetails
}: BankDetailsProps) => {
  const gridRef = useRef() as MutableRefObject<AgGridReact> | null;
  const [rowData, setRowData] = useState<VendorBankDetails[] | undefined>(
    [...bankDetails!] || []
  );
  const dispatch = useDispatch<any>();
  const { banks } = useAppSelector((obj) => obj);
  const {
    register,
    formState: { errors },
    control,
    trigger,
    reset
  } = useForm({
    mode: "all",
    // reValidateMode: "onChange",
    resolver: yupResolver(bankDetailsSchema)
  });

  const [formData, setFormData] = useState<VendorBankDetails>({
    bankId: banks[0].value,
    branchName: "",
    bankName: banks[0].label,
    accountNo: ""
  });

  const [selectedBank, setSelectedBank] = useState<SelectedOption | null>(
    banks[0]
  );

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const onGridEdit = (val: any) => {
    setSelectedBank(banks.find((x) => x.value === val.data.bankId));
    setFormData(val.data);
  };

  const onGridDelete = (val: any) => {
    confirmAlert({
      title: "Delete Confirmation",
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          //@ts-ignore
          onClick: () => {
            setRowData((prev: any) => [
              ...prev.filter((x: VendorBankDetails) => x.id !== val.value)
            ]);

            dispatch(
              vendorActions.deleteBank(val.value),
              (res: any) => {
                console.log(bankDetails, val.val);
                setRowData((prev: any) => [
                  ...prev.filter((x: VendorBankDetails) => x.id !== val.value)
                ]);
              },
              (err: any) => {
                console.log("ERROR", err);
              }
            );
          }
        },
        {
          label: "No",
          //@ts-ignore
          onClick: () => {}
        }
      ]
    });
  };

  const [columnDefs] = useState([
    { field: "bankName", headerName: "Bank" },
    { field: "ifscCode", headerName: "IFSC Code" },
    { field: "branchName", headerName: "Branch" },
    { field: "accountNo", headerName: "Account #" },
    {
      headerName: "Actions",
      pinned: "right",
      cellRenderer: (val: any) => (
        <GridActions
          data={val}
          showEditButton={false}
          onDeleteClick={onGridDelete}
          onEditClick={onGridEdit}
        />
      ),
      field: "id",
      colId: "params"
    }
  ]);

  const defaultColDef = useMemo(() => {
    return {
      filter: true,
      floatingFilter: true,
      resizable: true
    };
  }, []);

  const onFirstDataRendered = useCallback((params: any) => {
    gridRef?.current.api.sizeColumnsToFit();
  }, []);

  const onAdd = async (e: any) => {
    if (Object.entries(errors).length > 0) return;
    const tmpData = rowData;
    const idx = tmpData?.findIndex((obj) => obj.id === formData.id);
    if (idx! >= 0) {
      tmpData![idx!] = formData;
      setRowData([...tmpData!]);
    } else {
      const data = { ...formData, id: Math.random() * 100 + 1 };
      setRowData((prev: any) => [...prev, { ...data }]);
    }

    setFormData((prev) => ({
      ...prev,
      accountName: "",
      ifscCode: "",
      accountNo: ""
    }));
    reset({
      accountName: "",
      ifscCode: "",
      accountNo: ""
    });
    trigger();
  };

  useEffect(() => {
    trigger();
  }, []);
  useEffect(() => {
    setBankDetails && setBankDetails(rowData);
  }, [rowData]);

  return (
    // <form onSubmit={handleSubmit(onSubmit)} noValidate>
    <>
      <div className="row g-2">
        <div className="col-md-3 col-lg-3 col-sm-12">
          <label className="form-label">Bank</label>
          <Controller
            name={"bank"}
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                className="react-select"
                value={selectedBank}
                options={banks}
                onChange={(option: any) => {
                  setSelectedBank(option);
                  setFormData((prev) => ({
                    ...prev,
                    bankName: option.label,
                    bankId: option.value
                  }));
                }}
              />
            )}
          />
        </div>
        <div className="col-md-3 col-lg-3 col-sm-12">
          <label className="form-label">Account #</label>
          <input
            {...register("accountNo")}
            className={`form-control ${errors?.accountNo ? `error` : ``} `}
            name={"accountNo"}
            placeholder="Account #"
            onChange={(e) => {
              setFormData((prev) => ({
                ...prev,
                accountNo: e.target.value,
                displayName: e.target.value
              }));
            }}
            value={formData.accountNo}
          />
          {errors?.accountNo && (
            <div className="invalid-feedback">{errors?.accountNo?.message}</div>
          )}
        </div>
        <div className="col-md-3 col-lg-3 col-sm-12 mb-2">
          <label className="form-label">IFSC Code</label>
          <input
            {...register("ifscCode")}
            className={`form-control ${errors?.ifscCode ? `error` : ``} `}
            name={"ifscCode"}
            placeholder="IFSC code"
            onChange={handleChange}
            value={formData.ifscCode}
          />
          {errors?.ifscCode && (
            <div className="invalid-feedback">{errors?.ifscCode?.message}</div>
          )}
        </div>
        <div className="col-md-3 col-lg-3 col-sm-12 mb-2">
          <label className="form-label">Branch Name</label>
          <input
            className={`form-control`}
            name={"branchName"}
            placeholder="Branch Name"
            onChange={handleChange}
            value={formData.branchName}
          />
        </div>

        <div className="col-md-3 col-lg-3 col-sm-12 mb-2">
          <label className="form-label">Name on Bank Account</label>
          <input
            {...register("accountName")}
            className={`form-control ${errors?.accountName ? `error` : ``} `}
            name={"accountName"}
            placeholder="Account Name"
            onChange={handleChange}
            value={formData.accountName}
          />
          {errors?.accountName && (
            <div className="invalid-feedback">
              {errors?.accountName?.message}
            </div>
          )}
        </div>

        <div className="col-md-3 col-lg-3 col-sm-12 mb-2">
          <label className="form-label">Acount nick name</label>
          <input
            className={`form-control`}
            name={"displayName"}
            placeholder="Display Name"
            onChange={handleChange}
            value={formData.displayName}
          />
        </div>

        <div className="col-lg-12 col-md-12 col-sm-12">
          <button
            type="button"
            className="btn btn-success btn-sm"
            onClick={onAdd}
          >
            <i className="fa-solid fa-plus"></i>
            {" Add"}
          </button>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="card">
            <div className="card-body">
              <div className="ag-theme-balham agGrid-height-width">
                <AgGridReact
                  ref={gridRef}
                  rowData={rowData}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  onFirstDataRendered={onFirstDataRendered}
                ></AgGridReact>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export type VendorBankDetails = {
  id?: number | undefined;
  vendorId?: number | undefined;
  bankId?: number | undefined;
  bankName?: string | undefined;
  accountNo: string | undefined;
  branchName?: string | undefined;
  ifscCode?: string | undefined;
  accountName?: string | undefined;
  displayName?: string | undefined;
};

export type SelectedOption = {
  value: string | number;
  label: string;
};
