import { Actions } from ".";
import { dashboardTypes } from "../constTypes";

export type Dashboard = {
    months_overview: MonthsOverview[]
}

export type MonthsOverview = {
    paymentType: string;
    month: string;
    chequeAmount: number;
}

const initialState: Dashboard = {
    months_overview: [],
};

export const dashboard = (state = initialState, action: Actions) => {

    switch (action.type) {
        case dashboardTypes.MONTHS_OVERVIEW:
            return {
                ...state,
                months_overview: action.payload
            }

        default:
            return state;
    }
};

