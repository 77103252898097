import axios from "../../components/common/axios";
import { AxiosResponse } from "axios";
import { format } from "date-fns";
import { Callbacks } from "./common";
import { CHEQUE_ENTRY_LIST } from "../constTypes/cheque";
import { PayableReceivableType } from "../../components/Reports";


export const _date_wise_receivable_payable = (data: any) => {
    return {
        type: CHEQUE_ENTRY_LIST,
        payload: data,
    };
};

export const date_wise_receivable_payable = (data: PayableReceivableType, callbacks?: Callbacks) => async (dispatch: any) => {

    await axios.get(`/api/reports/date_wise_receivable_payable?startDate=${format(data?.fromDate!, "yyyy-MM-dd")}&endDate=${format(data?.toDate!, "yyyy-MM-dd")}`).then((res: AxiosResponse) => {
        // toast.success("Added successfully!!!")
        dispatch(_date_wise_receivable_payable(res.data.rows));
        if (callbacks?.onSuccess) callbacks?.onSuccess(res)
    }).catch((e) => {
        // toast.error(e.toString())
        if (callbacks?.onError) callbacks?.onError(e);
    })

}

export const overdue_cheque_details = (data: PayableReceivableType, callbacks?: Callbacks) => async (dispatch: any) => {

    await axios.get(`/api/reports/overdue_cheque_details`).then((res: AxiosResponse) => {
        dispatch(_date_wise_receivable_payable(res.data.rows));
        if (callbacks?.onSuccess) callbacks?.onSuccess(res)
    }).catch((e) => {
        if (callbacks?.onError) callbacks?.onError(e);
    })

}

export const vendor_cheque_details = (data: PayableReceivableType, callbacks?: Callbacks) => async (dispatch: any) => {

    await axios.get(`/api/reports/vendor_cheque_details?startDate=${format(data?.fromDate!, "yyyy-MM-dd")}&endDate=${format(data?.toDate!, "yyyy-MM-dd")}&vendorId=${data?.vendorId}`).then((res: AxiosResponse) => {
        dispatch(_date_wise_receivable_payable(res.data.rows));
        if (callbacks?.onSuccess) callbacks?.onSuccess(res)
    }).catch((e) => {
        if (callbacks?.onError) callbacks?.onError(e);
    })

}