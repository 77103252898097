import { Actions } from ".";
import * as types from "../constTypes";

const initialState: Common = {
  states: [],
  cities: [],
  roles: [],
  houseType: [{
    value: 1,
    label: "Home"
  },
  {
    value: 2,
    label: "Office"
  },
  {
    value: 3,
    label: "Others"
  }]
};

const initialArray: Options[] = []

export const geoLocation = (state = initialState, action: Actions) => {
  switch (action.type) {
    case types.commonTypes.GET_STATES:
      return {
        ...state,
        states: [...action.payload as States[]],
      };
    case types.commonTypes.GET_CITIES:
      return {
        ...state,
        cities: [...action.payload as Cities[]]
      };
    default:
      return state;
  }
};

export const status = (state = initialState, action: Actions) => {
  switch (action.type) {
    case types.commonTypes.ADD_STATUS:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
};

export const banks = (state: Options[] = initialArray, action: Actions) => {
  switch (action.type) {
    case types.commonTypes.GET_BANKS:
      return [...action.payload]
    default:
      return state;
  }
};
export const documents = (state: Options[] = initialArray, action: Actions) => {
  switch (action.type) {
    case types.commonTypes.GET_DOCUMENTS:
      return [...action.payload]
    default:
      return state;
  }
};

export const roles = (state: Options[] = initialArray, action: Actions) => {
  switch (action.type) {
    case types.commonTypes.GET_ROLE_MASTER:
      return [...action.payload]
    default:
      return state;
  }
};

export const businessTypes = (state: Options[] = initialArray, action: Actions) => {
  switch (action.type) {
    case types.commonTypes.GET_BUSINESS_TYPE:
      return [...action.payload]
    default:
      return state;
  }
};

export const role_details = (state: Roles[] = [], action: Actions) => {
  switch (action.type) {
    case types.commonTypes.GET_ROLES:
      return [...action.payload]
    default:
      return state;
  }
};
export type Cities = {
  value: string | number;
  label: string;
  state_id: string | number;
}

export type States = {
  value: string | number;
  label: string;
  country_id?: string | number;
}
export type Options = {
  value: string | number;
  label: string;
}

export type Common = {
  states: States[];
  cities: Cities[];
  houseType: Options[];
  roles: Options[];
}

export type Roles = {
  id?: number;
  roleName: string;
  value?: number;
  label?: string;
  roleDescription?: string;
  roleTransaction?: RoleTransaction[];
}

export type RoleTransaction = {
  id?: number;
  roldeId: number;
  menuId: number;
  canView: boolean;
  canAdd: boolean;
  canUpdate: boolean;
  canDelete: boolean;

}