import axios from "../../components/common/axios";
import { AxiosResponse } from "axios";
import { Callbacks } from "./ChequeTransactions";
import { MONTHS_OVERVIEW } from "../constTypes/dashboard";

const _months_overview = (data: any) => {
    return {
        type: MONTHS_OVERVIEW,
        payload: data,
    };
};

export const get_months_overview = ({ onSuccess, onError }: Callbacks) => async (dispatch: any) => {

    await axios.get(`/api/cheque/months_overview`).then((res: AxiosResponse) => {
        // toast.success("Added successfully!!!")
        console.log(res.data.rows)
        dispatch(_months_overview(res.data.rows));
        if (onSuccess) onSuccess(res)
    }).catch((e) => {
        // toast.error(e.toString())
        if (onError) onError(e);
    })

}