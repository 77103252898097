import Collapse, { Panel } from "rc-collapse";
import "rc-collapse/assets/index.css";
import { Link } from "react-router-dom";

const AppSideMenu = () => {
  const renderMenu = () => {
    return (
      // <Panel
      //   header={<div>{`Dashboard`}</div>}
      //   key={Math.random()}
      //   className="main-item"
      // >

      <Collapse accordion={true} key={Math.random()}>
        <Panel
          header={
            <div>
              <i className="fa-solid fa-house-chimney pe-3"></i>
              {`Masters`}
            </div>
          }
          className="themesub-item sidebar-item"
        >
          <ul className="sidebar-dropdown list-unstyled collapse show">
            <li className="sidebar-item">
              <Link
                to="/app/vendor"
                className="text-decoration-none sidebar-link"
              >
                Customer/Vendor
              </Link>
            </li>

            <li className="sidebar-item">
              <Link
                to="/app/users"
                className="text-decoration-none sidebar-link"
              >
                Users
              </Link>
            </li>

            <li className="sidebar-item">
              <Link
                to="/app/role"
                className="text-decoration-none sidebar-link"
              >
                Role
              </Link>
            </li>
          </ul>
        </Panel>
        <Panel
          header={
            <div>
              <i className="fa-solid fa-money-bill-transfer pe-3"></i>
              {`Cheque Transactions`}
            </div>
          }
          className="themesub-item"
        >
          <ul className="sidebar-dropdown list-unstyled collapse show">
            <li className="sidebar-item active">
              <Link
                to="/app/cheque"
                className="text-decoration-none sidebar-link"
              >
                Cheque Entries List
              </Link>
            </li>
            <li className="sidebar-item active">
              <Link
                to="/app/cheque/receivables"
                className="text-decoration-none sidebar-link"
              >
                Receivables
              </Link>
            </li>
            <li className="sidebar-item active">
              <Link
                to="/app/cheque/payables"
                className="text-decoration-none sidebar-link"
              >
                Payables
              </Link>
            </li>
            <li className="sidebar-item active">
              <Link
                to="/app/cheque/update-status"
                className="text-decoration-none sidebar-link"
              >
                Update Cheque Status
              </Link>
            </li>
          </ul>
        </Panel>
        <Panel
          header={
            <div>
              <i className="fa-solid fa-gears pe-3"></i>
              {`Configurations`}
            </div>
          }
          className="themesub-item"
        >
          <ul className="sidebar-dropdown list-unstyled collapse show">
            <li className="sidebar-item active">
              <Link
                to="/app/settings"
                className="text-decoration-none sidebar-link"
              >
                Settings
              </Link>
            </li>
          </ul>
        </Panel>
        <Panel
          header={
            <div>
              <i className="fa-solid fa-file-lines pe-4"></i>
              {`Reports`}
            </div>
          }
          className="themesub-item"
        >
          <ul className="sidebar-dropdown list-unstyled collapse show">
            <li className="sidebar-item active">
              <Link
                to="/app/reports/date_wise_payable_receivable"
                className="text-decoration-none sidebar-link"
              >
                {`Date Wise Receivable Payable`}
              </Link>
            </li>
            <li className="sidebar-item active">
              <Link
                to="/app/reports/overdue_cheque_details"
                className="text-decoration-none sidebar-link"
              >
                {`Overdue Cheque Details`}
              </Link>
            </li>
            <li className="sidebar-item active">
              <Link
                to="/app/reports/vendor_cheque_details"
                className="text-decoration-none sidebar-link"
              >
                {`Vendor/customer wise cheque details`}
              </Link>
            </li>
          </ul>
        </Panel>
      </Collapse>
      // </Panel>
    );
  };

  return (
    <ul className="sidebarmenu-nav">
      <Collapse accordion={true}>{renderMenu()}</Collapse>
    </ul>
  );
};
export default AppSideMenu;
