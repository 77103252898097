/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  ReactTabulator,
  ColumnDefinition,
  reactFormatter
} from "react-tabulator";
import { commonActions } from "../../redux/actions";
import { useAppSelector } from "../../redux/store/hooks";
import GridActions from "../common/GridActions";
import "react-tabulator/lib/styles.css";
import "react-tabulator/lib/css/tabulator_semanticui.min.css";

export const Role = () => {
  const dispatch = useDispatch<any>();
  const { roles } = useAppSelector((obj) => obj);

  const onGridEdit = (data: any) => {
    console.log(data);
  };
  const [columns] = useState<ColumnDefinition[]>([
    { field: "roleName", title: "Role Name" },
    {
      field: "id",
      title: "Actions",
      formatter: reactFormatter(
        <GridActions
          data={null}
          isAggrid={false}
          onEditClick={onGridEdit}
          //   onDeleteClick={onGridDelete}
          viewButton={false}
          //   onViewClick={handleView}
        />
      )
    }
  ]);

  useEffect(() => {
    dispatch(commonActions.get_role_master());
  }, []);

  return (
    <div className="container-fluid">
      <h1 className="header-title">Role Master</h1>
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12 pb-1 float-right d-flex flex-row-reverse">
          <Link to={"/app/vendor/add"} className="btn btn-success btn-sm">
            <i className="fa-solid fa-user-plus"></i>
            {" Add new roles for users"}
          </Link>
        </div>

        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="card">
            <div className="card-body">
              <div className="ag-theme-balham agGrid-height-width">
                <ReactTabulator
                  data={roles}
                  tooltips={true}
                  layout={"fitData"}
                  columns={columns}
                />
                {/* <AgGridReact
                  ref={gridRef}
                  rowData={rowData}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  onFirstDataRendered={onFirstDataRendered}
                ></AgGridReact> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Role;
